<template>
  <el-form :model="form" label-position="top" :rules="TransferFormRules" ref="withdrawFormChild">
    <div class="form_list_inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.default.selectBA')" prop="selectedCardID">
            <el-select v-model="form.selectedCardID" data-testid="selectedCardID">
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, 2, dropdownTranslationObj)"
                :data-testid="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div v-if="form.selectedCardID || form.selectedCardID === 0">
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.BankName')" prop="bankName">
              <el-select v-model="form.bankName" filterable :disabled="isdisabled" data-testid="bankName">
                <el-option
                  v-for="bank in bankList"
                  :key="bank.value"
                  :value="bank.value"
                  :label="bank.label"
                  :data-testid="bank.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.AccountNumberIBAN')" prop="accountNumber">
              <el-input
                maxlength="100"
                type="text"
                v-model.trim="form.accountNumber"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="accountNumber"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.BankBeneficiaryName')" prop="beneficiaryName">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.beneficiaryName"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="beneficiaryName"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.ImportantNotes')">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.importantNotes"
                auto-complete="off"
                data-testid="importantNotes"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
        <el-checkbox v-model="isRemember" data-testid="remember"> {{ $t('withdraw.default.remember') }}</el-checkbox>
      </div>
    </div>
  </el-form>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';

export default {
  props: ['withdrawalType', 'bankList'],
  mixins: [bankTransferMixin, withdrawlMixin],
  data() {
    return {
      form: {
        bankName: '',
        bankAddress: '',
        accountNumber: '',
        beneficiaryName: '',
        importantNotes: '',
        userPaymentInfoId: '',
        selectedCardID: null
      },
      isRemember: true,
      TransferFormRules: {
        bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankName'),
            trigger: 'blur'
          }
        ],
        bankAddress: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankAddress'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccountNumber,
            trigger: 'blur'
          }
        ],
        beneficiaryName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.beneName'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    },
    'form.selectedCardID'(value) {
      let selectedCardInfo = this.availableCards.find(item => item.id === value);
      if (selectedCardInfo && selectedCardInfo.id !== -1) {
        this.form = {
          ...this.form,
          bankName: selectedCardInfo.bankName,
          bankAddress: selectedCardInfo.bankAddress,
          accountNumber: selectedCardInfo.accountNumber,
          beneficiaryName: selectedCardInfo.beneficiaryName,
          importantNotes: selectedCardInfo.importantNotes,
          isRememberInfo: selectedCardInfo.id ? '' : this.isRemember,
          userPaymentInfoId: selectedCardInfo.id ? selectedCardInfo.id : ''
        };
        this.isdisabled = true;
      } else {
        //choose another
        this.form = {
          ...this.form,
          bankName: '',
          bankAddress: '',
          accountNumber: '',
          beneficiaryName: '',
          importantNotes: '',
          userPaymentInfoId: '',
          isRememberInfo: this.isRemember
        };
        this.isdisabled = false;
      }
    },
    'form.accountNumber'(value) {
      this.form.accountNumber = this.latinNumberValidator(value);
    },
    'form.beneficiaryName'(value) {
      this.form.beneficiaryName = this.latinNumberValidator(value);
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormChild.validate();
    }
  }
};
</script>
