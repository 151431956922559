import i18n from '@/lang'

export default {
  data() {
    return { form: { isRememberInfo: true }, isdisabled: false }
  },
  methods: {
    langTranslate(prefix, key) {
      const filteredKey = key ? key.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/g, '').toLowerCase() : '';
      const comparedKey = prefix + filteredKey;
      const translatedLang = i18n.t(prefix + filteredKey);

      return comparedKey === translatedLang ? key : translatedLang;
      // return translatedLang // Show Translate Key
    },
  }
}
