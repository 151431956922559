<template>
  <el-form :model="form" label-position="top" :rules="TransferFormRules" ref="withdrawForm">
    <div class="form_list_inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.default.selectBA')" prop="selectedCardID">
            <el-select v-model="form.selectedCardID" data-testid="selectedCardID">
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, 2, dropdownTranslationObj)"
                :data-testid="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div v-if="form.selectedCardID || form.selectedCardID === 0">
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.BankName')" prop="bankName">
              <el-select v-model="form.bankName" :disabled="isdisabled" data-testid="bankName">
                <el-option
                  v-for="item in bankList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                  :data-testid="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.BankBeneficiaryName')" prop="beneficiaryName">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.beneficiaryName"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="beneficiaryName"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.BankAccountNumber')" prop="accountNumber">
              <el-input
                maxlength="100"
                type="text"
                v-model.trim="form.accountNumber"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="accountNumber"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.ImportantNotes')">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.importantNotes"
                auto-complete="off"
                data-testid="importantNotes"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.DocumentType')" prop="documentType" required>
              <el-select v-model="form.documentType" :disabled="isdisabled" data-testid="documentType">
                <el-option
                  v-for="item in documentType"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                  :data-testid="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.DocumentDetails')" prop="documentDetails">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.documentDetails"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="documentDetails"
              ></el-input>
            </el-form-item>
          </li>

          <!-- BRAZIL ONLY -->
          <li v-if="userCountryCode == countryCode.BRAZIL">
            <el-form-item :label="$t('withdraw.PixKeyType')" prop="pixKeyType">
              <el-select v-model="form.pixKeyType" :disabled="isdisabled" data-testid="pixKeyType">
                <el-option
                  v-for="item in pixKeyType"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                  :data-testid="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </li>

          <li v-if="userCountryCode == countryCode.BRAZIL">
            <el-form-item :label="$t('withdraw.PixKey')" prop="pixKey">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.pixKey"
                auto-complete="off"
                data-testid="pixKey"
              ></el-input>
            </el-form-item>
          </li>

          <!-- MEXICO ONLY -->
          <li v-if="userCountryCode == countryCode.MEXICO">
            <el-form-item :label="$t('withdraw.AccountType')" prop="accountTypeMexico">
              <el-select v-model="form.accountTypeMexico" :disabled="isdisabled" data-testid="accountTypeMexico">
                <el-option
                  v-for="item in accountType"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                  :data-testid="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </li>

          <li v-if="userCountryCode == countryCode.MEXICO">
            <el-form-item :label="$t('withdraw.AccountDetails')" prop="accountDetailsMexico">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.accountDetailsMexico"
                auto-complete="off"
                data-testid="accountDetailsMexico"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form_list_inner clearfix">
        <ul>
          <li v-if="!isdisabled">
            <el-form-item :label="$t('withdraw.transfer.label.upload')" class="upload" prop="uploadedFile">
              <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo" data-testid="uploadedFile"></vUpload>
            </el-form-item>
          </li>
        </ul>
      </div>

      <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
        <el-checkbox v-model="isRememberInfo" data-testid="remember">
          {{ $t('withdraw.default.remember') }}</el-checkbox
        >
      </div>
    </div>
  </el-form>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import vUpload from '@/components/vUpload';
import { countryCodeEnum } from '@/constants/country';

export default {
  props: ['withdrawalType', 'bankList', 'userCountryCode'],
  mixins: [bankTransferMixin, withdrawlMixin],
  components: { vUpload },
  data() {
    const validateFile = (rule, value, callback) => {
      if (this.fileList.length < 1) callback(new Error(this.$t('withdraw.transfer.formValidation.upload')));
      else callback();
    };
    return {
      countryCode: countryCodeEnum,
      documentType:
        this.userCountryCode == countryCodeEnum.BRAZIL
          ? [
              { value: 'CPF', label: 'CPF' },
              { value: 'CNPJ', label: 'CNPJ' }
            ]
          : [
              { value: 'RFC', label: 'RFC' },
              { value: 'CURP', label: 'CURP' },
              { value: 'ND', label: 'ND' }
            ],
      pixKeyType: [
        { value: 'CPF', label: 'CPF' },
        { value: 'CNPJ', label: 'CNPJ' },
        { value: 'Phone', label: 'Phone' },
        { value: 'Email', label: 'Email' },
        { value: 'EVP', label: 'EVP' }
      ],
      accountType: [
        { value: 'Debit', label: 'Debit' },
        { value: 'Phone', label: 'Phone' },
        { value: 'Clabe', label: 'Clabe' }
      ],
      form: {
        bankName: '',
        beneficiaryName: '',
        accountNumber: '',
        documentType: '',
        documentDetails: '',
        pixKeyType: '',
        pixKey: '',
        accountTypeMexico: '',
        accountDetailsMexico: '',
        importantNotes: '',
        userPaymentInfoId: '',
        selectedCardID: null
      },
      fileList: [],
      isRememberInfo: true,
      TransferFormRules: {
        bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankName'),
            trigger: ['blur', 'change']
          }
        ],
        beneficiaryName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.beneName'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccountNumber,
            trigger: 'blur'
          }
        ],
        documentType: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.DocumentType')
            }),
            trigger: ['blur', 'change']
          }
        ],
        documentDetails: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.DocumentDetails')
            }),
            trigger: ['blur', 'change']
          }
        ],
        pixKeyType: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.PixKeyType')
            }),
            trigger: ['blur', 'change']
          }
        ],
        pixKey: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.PixKey')
            }),
            trigger: ['blur', 'change']
          }
        ],
        accountTypeMexico: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.AccountType')
            }),
            trigger: ['blur', 'change']
          }
        ],
        accountDetailsMexico: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.AccountDetails')
            }),
            trigger: ['blur', 'change']
          }
        ],
        uploadedFile: [
          {
            required: true,
            validator: validateFile,
            trigger: 'change'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    },
    'form.selectedCardID'(value) {
      const selectedCardInfo = this.availableCards.find(item => item.id === value);

      this.form = {
        ...this.form,
        bankName: selectedCardInfo.bankName || '',
        accountNumber: selectedCardInfo.accountNumber || '',
        beneficiaryName: selectedCardInfo.beneficiaryName || '',
        documentType: selectedCardInfo.documentType || '',
        documentDetails: selectedCardInfo.documentDetails || '',
        pixKeyType: selectedCardInfo.pixKeyType || '',
        pixKey: selectedCardInfo.pixKey || '',
        accountTypeMexico: selectedCardInfo.accountTypeMexico || '',
        accountDetailsMexico: selectedCardInfo.accountDetailsMexico || '',
        importantNotes: selectedCardInfo.importantNotes || '',
        isRememberInfo:
          selectedCardInfo && selectedCardInfo.id !== -1
            ? selectedCardInfo.id
              ? ''
              : this.isRemember
            : this.isRemember,
        userPaymentInfoId: selectedCardInfo && selectedCardInfo.id !== -1 ? selectedCardInfo.id : null
      };

      this.isdisabled = selectedCardInfo && selectedCardInfo.id !== -1 ? true : false;
    },
    'form.accountNumber'(value) {
      this.form.accountNumber = this.latinNumberValidator(value);
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawForm.validate();
    },
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['withdrawForm'].validateField('uploadedFile');
    },
    getFormData() {
      return {
        withdrawalType: this.withdrawalType,
        bankName: this.form.bankName,
        beneficiaryName: this.form.beneficiaryName,
        accountNumber: this.form.accountNumber,
        documentType: this.form.documentType,
        documentDetails: this.form.documentDetails,
        pixKeyType: this.form.pixKeyType,
        pixKey: this.form.pixKey,
        accountTypeMexico: this.form.accountTypeMexico,
        accountDetailsMexico: this.form.accountDetailsMexico,
        importantNotes: this.form.importantNotes,
        isRememberInfo: this.form.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.form.userPaymentInfoId ? this.form.userPaymentInfoId : '',
        targetCurrency: this.form.withdrawCurrency,
        fileList: this.fileList
      };
    }
  }
};
</script>
