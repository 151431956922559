<template>
  <div id="ebuyForm">
    <el-form label-position="top" :model="form" ref="form" :rules="rules">
      <el-row :gutter="40">
        <!-- <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('withdraw.ebuy.field.email')" prop="ebuyEmail">
            <el-input v-model="form.ebuyEmail" type="email" data-testid="ebuyEmail"></el-input>
          </el-form-item>
        </el-col> -->
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('withdraw.ImportantNotes')" prop="importantNotes">
            <el-input v-model="form.importantNotes" data-testid="importantNotes"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { validateEmail } from '@/util/validation';

export default {
  name: 'ebuyWithdraw',
  props: { withdrawalType: Number },
  data() {
    return {
      form: {
        // ebuyEmail: '',
        importantNotes: ''
      },
      rules: {
        ebuyEmail: [
          {
            required: true,
            message: this.$t('withdraw.ebuy.formValidation.emailReq'),
            trigger: 'blur'
          },
          {
            validator: validateEmail,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  computed: {
    countryCode() {
      return this.$store.state.common.countryCode;
    }
  },
  methods: {
    getFormData() {
      return {
        withdrawalType: this.withdrawalType,
        ebuyEmail: this.form.ebuyEmail,
        importantNotes: this.form.importantNotes
      };
    },
    submitForm() {
      return this.$refs.form.validate();
    }
  }
};
</script>
