<template>
  <el-form :model="form" label-position="top" :rules="TransferFormRules" ref="withdrawForm">
    <div class="form_list_inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.default.selectBA')" prop="selectedCardID">
            <el-select v-model="form.selectedCardID" data-testid="selectedCardID">
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, 2, dropdownTranslationObj, that)"
                :data-testid="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div v-if="form.selectedCardID || form.selectedCardID === 0">
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.BankName')" prop="bankName">
              <el-select v-model="form.bankName" :disabled="isdisabled" data-testid="bankName" filterable>
                <el-option
                  v-for="item in bankList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                  :data-testid="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.Fullname')" prop="beneficiaryName">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.beneficiaryName"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="beneficiaryName"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.AccNumber')" prop="accountNumber">
              <el-input
                maxlength="100"
                type="text"
                v-model.trim="form.accountNumber"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="accountNumber"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.AccountNumberdigit')" prop="AccountNumberdigit" required>
              <el-input
                v-model="form.AccountNumberdigit"
                data-testid="AccountNumberdigit"
                :disabled="isdisabled"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.DocumentNumber')" prop="documentDetails">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.documentDetails"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="documentDetails"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.ImportantNotes')">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.importantNotes"
                auto-complete="off"
                data-testid="importantNotes"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>

      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.DocumentType')" prop="documentTypes">
              <el-select v-model="form.documentTypes" data-testid="documentTypes" :disabled="isdisabled">
                <el-option
                  v-for="(item, index) in documentTypes"
                  :key="index"
                  :label="item"
                  :value="item"
                  :data-testid="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </li>
        </ul>
      </div>

      <!-- <div class="form_list_inner clearfix">
        <ul>
          <li v-if="!isdisabled">
            <el-form-item :label="$t('withdraw.transfer.label.upload')" class="upload" prop="uploadedFile">
              <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo" data-testid="uploadedFile"></vUpload>
            </el-form-item>
          </li>
        </ul>
      </div> -->

      <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
        <el-checkbox v-model="isRememberInfo" data-testid="remember">
          {{ $t('withdraw.default.remember') }}</el-checkbox
        >
      </div>
    </div>
  </el-form>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import vUpload from '@/components/vUpload';
import { countryCodeEnum } from '@/constants/country';
import { apiBee_documentTypes } from '@/resource';

export default {
  props: ['withdrawalType', 'bankList', 'userCountryCode'],
  mixins: [bankTransferMixin, withdrawlMixin],
  components: { vUpload },
  data() {
    const validateFile = (rule, value, callback) => {
      if (this.fileList.length < 1) callback(new Error(this.$t('withdraw.transfer.formValidation.upload')));
      else callback();
    };
    return {
      that: this,
      countryCode: countryCodeEnum,
      accountType: [
        { value: 'Debit', label: 'Debit' },
        { value: 'Phone', label: 'Phone' },
        { value: 'Clabe', label: 'Clabe' }
      ],
      form: {
        bankName: '',
        beneficiaryName: '',
        accountNumber: '',
        documentTypes: '',
        documentDetails: '',
        importantNotes: '',
        userPaymentInfoId: '',
        selectedCardID: null,
        AccountNumberdigit: ''
      },
      documentTypes: [],
      fileList: [],
      isRememberInfo: true,
      TransferFormRules: {
        bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankName'),
            trigger: ['blur', 'change']
          }
        ],
        beneficiaryName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.Fullname')
            }),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccountNumber,
            trigger: 'blur'
          }
        ],
        documentTypes: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              dynamic: this.$t('withdraw.documentType')
            }),
            trigger: ['blur', 'change']
          }
        ],
        documentDetails: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.DocumentNumber')
            }),
            trigger: ['blur', 'change']
          },
          {
            validator: (rule, value, callback) => {
              var reg = null;

              console.log('this.form.documentTypes >> ' + this.form.documentTypes);

              switch (this.form.documentTypes) {
                // Chile
                case 'RUT':
                  reg = /^[0-9]{1,2}[.]?[0-9]{3}[.]?[0-9]{3}-?[0-9Kk]$/;
                  this.documentValidatorMsg(
                    reg,
                    value,
                    callback,
                    this.$t('withdraw.transfer.formValidation.digitsAnytoAny', { number: 8, number2: 9 })
                  );
                  break;

                // Colombia
                case 'NIT':
                  reg = /^\d{10}$/;
                  this.documentValidatorMsg(
                    reg,
                    value,
                    callback,
                    this.$t('withdraw.transfer.formValidation.digitsAny', { number: 10 })
                  );
                  break;

                case 'CC':
                  reg = /^\d{6,10}$/;
                  this.documentValidatorMsg(
                    reg,
                    value,
                    callback,
                    this.$t('withdraw.transfer.formValidation.digitsAnytoAny', { number: 6, number2: 10 })
                  );
                  break;

                // Mexico
                case 'RFC':
                  reg = /^\w{12,13}$/;
                  this.documentValidatorMsg(
                    reg,
                    value,
                    callback,
                    this.$t('withdraw.transfer.formValidation.alphanumericAnytoAny', { number: 12, number2: 13 })
                  );
                  break;

                // Ecuador;
                case 'RUC':
                  reg = /^\d{13}$/;
                  this.documentValidatorMsg(
                    reg,
                    value,
                    callback,
                    this.$t('withdraw.transfer.formValidation.digitsAny', { number: 13 })
                  );
                  break;

                case 'CE':
                  reg = /^\d{10}$/;
                  this.documentValidatorMsg(
                    reg,
                    value,
                    callback,
                    this.$t('withdraw.transfer.formValidation.digitsAny', { number: 10 })
                  );
                  break;

                // Brazil
                case 'CPF':
                  reg = /^\d{11}$/;
                  this.documentValidatorMsg(
                    reg,
                    value,
                    callback,
                    this.$t('withdraw.transfer.formValidation.digitsAny', { number: 11 })
                  );
                  break;

                case 'CNPJ':
                  reg = /^\d{14}$/;
                  this.documentValidatorMsg(
                    reg,
                    value,
                    callback,
                    this.$t('withdraw.transfer.formValidation.digitsAny', { number: 14 })
                  );
                  break;

                default:
                  callback();
                  break;
              }
            },
            trigger: 'blur'
          }
        ],
        uploadedFile: [
          {
            required: true,
            validator: validateFile,
            trigger: 'change'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    },
    'form.selectedCardID'(value) {
      const selectedCardInfo = this.availableCards.find(item => item.id === value);

      this.form = {
        ...this.form,
        bankName: selectedCardInfo.bankName || '',
        accountNumber: selectedCardInfo.accountNumber || '',
        documentTypes: selectedCardInfo.documentTypes || (this.documentTypes.length > 0 ? this.documentTypes[0] : ''),
        beneficiaryName: selectedCardInfo.beneficiaryName || '',
        documentDetails: selectedCardInfo.documentDetails || '',
        AccountNumberdigit: selectedCardInfo.swift || '',
        importantNotes: selectedCardInfo.importantNotes || '',
        isRememberInfo:
          selectedCardInfo && selectedCardInfo.id !== -1
            ? selectedCardInfo.id
              ? ''
              : this.isRemember
            : this.isRemember,
        userPaymentInfoId: selectedCardInfo && selectedCardInfo.id !== -1 ? selectedCardInfo.id : null
      };

      this.isdisabled = selectedCardInfo && selectedCardInfo.id !== -1 ? true : false;
    },
    'form.accountNumber'(value) {
      this.form.accountNumber = this.latinNumberValidator(value);
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawForm.validate();
    },
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['withdrawForm'].validateField('uploadedFile');
    },
    getFormData() {
      return {
        withdrawalType: this.withdrawalType,
        bankName: this.form.bankName,
        beneficiaryName: this.form.beneficiaryName,
        accountNumber: this.form.accountNumber,
        documentType: this.form.documentTypes,
        documentDetails: this.form.documentDetails,
        swift: this.form.AccountNumberdigit,
        importantNotes: this.form.importantNotes,
        isRememberInfo: this.form.userPaymentInfoId ? '' : this.isRememberInfo,
        targetCurrency: this.form.withdrawCurrency
        // userPaymentInfoId: this.form.userPaymentInfoId ? this.form.userPaymentInfoId : '',
        // fileList: this.fileList
      };
    },
    async getBeePaymentTypes() {
      let res = await apiBee_documentTypes();

      if (res.data.code == 0) {
        let { documentTypes = [] } = res.data.data || {};
        this.documentTypes = documentTypes;
      }
    },
    documentValidatorMsg(reg, value, callback, errorMsg) {
      if (!reg.test(value)) {
        callback(errorMsg);
      } else {
        callback();
      }
    }
  },
  mounted() {
    this.getBeePaymentTypes();
  }
};
</script>
