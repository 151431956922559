<template>
  <el-form :model="withdrawForm" label-position="top" :rules="rulesWithdraw" ref="withdrawForm">
    <div class="form_list_inner">
      <span class="special_title mb-2">{{ $t('common.keys.FROM') }}</span>
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('common.keys.rebateAccount')" prop="qAccount">
            <el-select v-model="withdrawForm.qAccount" @change="rebateAccountChange" data-testid="rebateAccount">
              <el-option
                v-for="item in accountList"
                :key="item.login"
                :label="item.login + ' - ' + item.balance + ' ' + item.currency"
                :value="item.login"
                :data-testid="item.login"
              ></el-option>
            </el-select>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="USCAmount" prop="amount">
            <numeric-input
              ref="numberInput"
              v-model="withdrawForm.amount"
              :currency="withdrawForm.currency"
              :precision="2"
            ></numeric-input>
          </el-form-item>
        </li>
      </ul>
      <!-- unipay出金小于100需要支付20的手续费 -->
      <p
        v-if="withdrawForm.withdrawalType == 4 && withdrawForm.amount < this.units && withdrawForm.amount !== 0"
        class="service_text"
      >
        {{ $t('withdraw.default.withdrawlFeeAlert') }}
      </p>
    </div>
    <div class="form_list_inner to" :class="{ 'cursor-not-allowed opacity-0-6': hasNegativeBalance }">
      <span class="special_title mb-2">{{ $t('common.keys.TO') }}</span>
      <ul class="clearfix">
        <li class="channel">
          <el-form-item :label="$t('withdraw.PleaseSelectDesiredWithdrawMethod')">
            <el-radio-group v-model="withdrawForm.withdrawalType" data-testid="withdrawalType">
              <ul class="clearfix">
                <li v-for="item in withdrawalTypes" :key="item.id">
                  <el-radio :disabled="hasNegativeBalance" :label="item.id" :data-testid="'withdrawalType' + item.id">{{
                    $t(item.nameKey)
                  }}</el-radio>
                </li>
              </ul>
            </el-radio-group>
          </el-form-item>
        </li>
      </ul>
    </div>
    <el-alert
      class="mb-4"
      v-if="hasNegativeBalance"
      title=""
      type="error"
      :description="$t('withdraw.negativeBalance', { accounts: negativeBalanceAcc.toString() })"
      :closable="false"
      show-icon
    >
    </el-alert>
  </el-form>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import { integerOrMaxTwoDecimalNumbersCreator } from '@/util/validators';
import { apiGetWithdrawalData } from '@/resource';

export default {
  name: 'Top',
  components: { NumericInput },
  props: { chooseWithdrawalType: Object },
  data() {
    return {
      charge: 20,
      units: 100,
      accountList: [],
      withdrawalTypes: [],
      countryBankList: [],
      countryCode: null,
      availableBalance: '',
      withdrawForm: {
        qAccount: '',
        amount: '',
        currency: '',
        withdrawalType: null
      },
      showNote: false,
      checkMethodMinAmount: false,
      hasNegativeBalance: false,
      negativeBalanceAcc: [],
      withdrawalTypesList: [],
      plnMaxWithdraw: 150000
    };
  },
  watch: {
    withdrawForm: {
      handler(val, oldVal) {
        this.$emit('withdrawFormChange', val, this.hasNegativeBalance);
      },
      deep: true
    },
    'withdrawForm.withdrawalType'(value) {
      this.validateAmountField();
    },
    'withdrawForm.qAccount'(val) {
      this.withdrawForm.withdrawalType = null;

      if (['USD', 'HKD', 'USC'].includes(this.withdrawForm.currency) && ['7033', '7069'].includes(this.countryCode)) {
        this.withdrawalTypes = JSON.parse(JSON.stringify(this.withdrawalTypesList));
      } else if (this.withdrawForm.currency === 'PLN') {
        this.withdrawalTypes = this.withdrawalTypes.filter(item => item.id === 2);
      } else {
        this.withdrawalTypes = this.withdrawalTypesList.filter(item => item.id != 49);
      }

      if (this.withdrawForm.currency !== 'USD') {
        this.withdrawalTypes = this.withdrawalTypes.filter(item => item.id !== 64 && item.id !== 112);
      }
    }
  },
  computed: {
    currencyType() {
      return this.withdrawForm.currency === 'USC' ? '￠' : '$';
    },
    USCAmount() {
      return (
        this.$t('common.keys.amount') +
        (this.withdrawForm.currency === 'USC'
          ? this.$t('common.keys.actualAmount') + this.withdrawForm.amount / 100 + ' USD'
          : '')
      );
    },
    minLimit() {
      if (this.withdrawForm.currency !== 'USC') {
        return this.availableBalance <= 30
          ? this.checkMethodMinAmount && this.chooseWithdrawalType
            ? this.chooseWithdrawalType.amountLimit
            : this.availableBalance
          : 30;
      } else {
        return this.availableBalance <= 3000
          ? this.checkMethodMinAmount && this.chooseWithdrawalType
            ? this.chooseWithdrawalType.amountLimit * 100
            : this.availableBalance
          : 3000;
      }
    },
    rulesWithdraw() {
      return {
        qAccount: [
          {
            required: true,
            message: this.$t('common.keys.pleaseSelect'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.amt'),
            trigger: 'blur'
          },
          {
            validator: integerOrMaxTwoDecimalNumbersCreator(this.$t('withdraw.CannotUseMoreThanTwoDecimalValues')),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (parseFloat(value) <= 0) {
                callback(new Error(this.$t('withdraw.formErrorMsg.amt0')));
              } else if (value < this.minLimit) {
                callback(
                  new Error(
                    this.$t('withdraw.amtLarger', {
                      currencyType: this.$options.filters.currencySymbol(this.withdrawForm.currency),
                      minLimit: this.minLimit
                    })
                  )
                );
              } else if (this.availableBalance <= 30 && value < this.availableBalance) {
                callback(
                  new Error(
                    this.$t('withdraw.amtLarger', {
                      currencyType: this.$options.filters.currencySymbol(this.withdrawForm.currency),
                      minLimit: this.availableBalance
                    })
                  )
                );
              } else if (value > this.availableBalance) {
                callback(new Error(this.$t('withdraw.YourPaymentGreaterThanAvailableBalance')));
              } else if (value % 1 !== 0 && this.withdrawForm.currency === 'USC' && value > 3000) {
                callback(new Error(this.$t('withdraw.integer')));
              } else if (this.withdrawForm.currency === 'PLN' && value > this.plnMaxWithdraw) {
                callback(
                  new Error(
                    this.$t('withdraw.plnWithdraw', {
                      money: this.plnMaxWithdraw.toLocaleString()
                    })
                  )
                );
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }
        ]
      };
    }
  },
  mounted() {
    this.getWithdrawalData();
  },
  methods: {
    getWithdrawalData() {
      this.checkMethodMinAmount = this.$platform.getCheckMethodMinAmount(this.regulator);
      apiGetWithdrawalData({
        qUserId: this.$store.state.common.CUID
      }).then(resp => {
        if (resp.data.code === 0) {
          const tradingAccounts = resp.data.data.negativeBalanceTradingAccounts;
          this.accountList = resp.data.data.logins;
          this.withdrawalTypes = resp.data.data.withdrawalType;
          this.countryCode = resp.data.data.countryCode;

          this.withdrawalTypesList = JSON.parse(JSON.stringify(this.withdrawalTypes));

          tradingAccounts.forEach(acc => {
            if (acc.balance < 0) {
              this.hasNegativeBalance = true;
              this.negativeBalanceAcc.push(acc.login);
            }
          });

          if (this.countryCode === '3983') {
            this.withdrawalTypes = this.russiaSanctionFilter(this.withdrawalTypes);
          }

          this.countryBankList = new Map(
            this.withdrawalTypes.map(witdrawType => {
              let withdrawBankList = witdrawType.withdrawBankList;
              let bankList = [];
              for (const key in withdrawBankList) {
                if (Object.hasOwnProperty.call(withdrawBankList, key)) {
                  const bank = withdrawBankList[key];
                  bankList.push({ label: bank, value: bank });
                }
              }
              bankList.sort((a, b) => a.label.localeCompare(b.label));
              return [witdrawType.id, bankList];
            })
          );
          this.checkRestrictedCountryByWithdrawType(2);
          this.$emit('setCountryCode', this.countryCode);
          this.$emit('setBankList', this.countryBankList);
          if (this.accountList && this.accountList.length > 0) {
            this.$nextTick(() => {
              // 默认选中首页选中的账号
              this.$set(this.withdrawForm, 'qAccount', this.accountList[0].login);
              this.rebateAccountChange(this.accountList[0].login);
            });
          }
        }
      });
    },
    rebateAccountChange(val) {
      if (val === '') {
        return;
      }

      const [currAccountInfo] = this.accountList.filter(item => {
        return item.login === val;
      });

      if (currAccountInfo) {
        this.availableBalance = currAccountInfo.balance;
        this.withdrawForm.currency = currAccountInfo.currency;
      }

      this.$nextTick(() => {
        this.validateAmountField();
      });
    },
    validateAmountField() {
      if (this.withdrawForm.amount) {
        this.$refs.withdrawForm.validateField('amount', valid => {
          if (!valid) return false;
        });
      }
    },
    submitForm() {
      return this.$refs.withdrawForm.validate();
    },
    checkRestrictedCountryByWithdrawType(withdrawType) {
      this.showNote = !this.withdrawalTypes.find(f => f.id === withdrawType) ? true : false;
    },
    russiaSanctionFilter(withdrawType) {
      let withdrawTypeList = withdrawType;
      const disabledMethod = [2];
      withdrawTypeList = withdrawTypeList.filter(list => !disabledMethod.includes(list.id));
      return withdrawTypeList;
    }
  }
};
</script>
