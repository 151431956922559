<template>
  <div id="transferCountry">
    <el-form label-position="top">
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.default.selectBA')" prop="selectedCardID">
              <el-select v-model="selectedCardID" data-testid="selectedCardID">
                <el-option
                  v-for="item in availableCards"
                  :key="item.id"
                  :value="item.id"
                  :label="item.id | cardInfoDropdown(item, 2, dropdownTranslationObj)"
                  :data-testid="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </li>
          <li>
            <WithdrawlSharedOptions
              v-model="transferCountryString"
              :label="chooseWithdrawalType.subTypeNameKey"
              :list="chooseWithdrawalType.withdrawSubTypes"
              :disabled="selectedCardID !== -1"
            ></WithdrawlSharedOptions>
          </li>
        </ul>
      </div>
      <component
        :is="currentComponent"
        ref="bankChild"
        :selectedCardInfo="selectedCardInfo"
        :transferCountryString="transferCountryString"
        :chooseWithdrawalType="chooseWithdrawalType"
        :withdrawalType="withdrawalType"
        :userCountryCode="userCountryCode"
        @formChange="formChange"
      ></component>
    </el-form>
  </div>
</template>
<script>
import International from '@/components/rebateWithdraw/International';
import Australia from '@/components/rebateWithdraw/Australia';
import UK from '@/components/rebateWithdraw/UK';
import WithdrawlSharedOptions from '@/components/rebateWithdraw/WithdrawlSharedOptions';
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';

export default {
  name: 'BankTransfer',
  components: {
    WithdrawlSharedOptions,
    International,
    Australia,
    UK
  },
  props: ['withdrawalType', 'chooseWithdrawalType', 'userCountryCode'],
  mixins: [bankTransferMixin, withdrawlMixin],
  data() {
    return {
      currentComponent: null,
      transferCountry: null,
      selectedCardInfo: null,
      transferCountryString: null,
      form: {}
    };
  },
  watch: {
    selectedCardID(value) {
      this.selectedCardInfo = this.availableCards.filter(item => item.id === value)[0];
      console.log(this.selectedCardInfo);
      this.transferCountry = this.selectedCardInfo.country;
      this.transferCountryString = this.transferCountry ? this.transferCountry.toString() : null;
      console.log(this.transferCountryString);
    },
    transferCountryString(value) {
      this.$emit('changeBankComponent', value);

      if (value === '1') {
        this.currentComponent = Australia;
      }

      if (value === '2') {
        this.currentComponent = International;
      }

      if (value === '4') {
        this.currentComponent = UK;
      }
    },
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    submitForm() {
      return this.$refs.bankChild.submitForm();
    },
    validateFormPropsHandler() {
      this.$emit('validateForm');
    },
    formChange(form) {
      this.form = form;
    }
  }
};
</script>
