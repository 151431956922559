<template>
  <el-form :model="form" label-position="top" :rules="rules" ref="form">
    <div class="form_list_inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.BitcoinEmail')" prop="cryptoWalletAddress">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.cryptoWalletAddress"
              auto-complete="off"
              data-testid="cryptoWalletAddress"
            ></el-input>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.enterVerCode')" prop="code">
            <div class="flex">
              <el-input v-model="form.code" data-testid="code"></el-input>
              <el-button :disabled="codeBtnDisabled" class="btn_color" data-testid="sendVerCode" @click="sendCode"
                >{{ $t('withdraw.sendVerCode') }}
                <span v-if="codeBtnDisabled"> ({{ count }}) </span>
              </el-button>
            </div>
            <p
              class="note"
              v-show="codeBtnDisabled"
              v-html="$t('withdraw.sendVerEmail', { sendEmail: getCryptoEmail(), mail: GLOBAL_CONTACT_EMAIL })"
            ></p>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.ImportantNotes')">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.importantNotes"
              auto-complete="off"
              data-testid="importantNotes"
            ></el-input>
          </el-form-item>
        </li>
      </ul>
      <div class="alert_info">
        {{ $t('withdraw.cryptoAlert') }}
      </div>
    </div>
  </el-form>
</template>

<script>
import cryptoSendVerCode from '@/mixins/withdrawl/cryptoSendVerCode';

export default {
  mixins: [cryptoSendVerCode],
  props: { withdrawalType: Number },

  data() {
    const validateAddress = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('withdraw.formErrorMsg.bitcoin')));
      } else if (!/^(bc|[13])/.test(value)) {
        callback(new Error(this.$t('withdraw.formErrorMsg.bitcoinFormat')));
      } else if (value.length < 26 || value.length > 42) {
        callback(new Error(this.$t('withdraw.formErrorMsg.bitcoinAddressLength')));
      } else if (value.includes(' ')) {
        callback(new Error(this.$t('withdraw.formErrorMsg.spaceReq')));
      } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
        callback(new Error(this.$t('withdraw.formErrorMsg.alphReq')));
      } else callback();
    };
    const validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('withdraw.formErrorMsg.invalidCode')));
      } else {
        callback();
      }
    };
    return {
      form: {
        cryptoWalletAddress: '',
        importantNotes: '',
        code: ''
      },
      rules: {
        cryptoWalletAddress: [
          {
            required: true,
            validator: validateAddress,
            trigger: 'blur'
          }
        ],
        code: [
          {
            required: true,
            validator: validateCode,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      return this.$refs.form.validate();
    },
    getFormData() {
      return {
        withdrawalType: this.withdrawalType,
        cryptoWalletAddress: this.form.cryptoWalletAddress,
        importantNotes: this.form.importantNotes,
        addressRemark: this.form.addressRemark,
        addressType: 1,
        code: this.form.code,
        txId: sessionStorage.getItem('txId')
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.btn_color {
  color: black !important;
  & {
    color: black !important;
  }
}
</style>
