<template>
  <el-form :model="form" label-position="top" :rules="rules" ref="alipayForm">
    <div class="form_list_inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.alipay.field.accountName')" prop="accountName">
            <el-input
              type="text"
              v-model="form.accountName"
              auto-complete="off"
              data-testid="accountName"
              @blur="form.accountName = form.accountName.trim()"
            ></el-input>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.alipay.field.email')" prop="email">
            <el-input type="email" v-model="form.email" auto-complete="off" data-testid="email"></el-input>
          </el-form-item>
        </li>
      </ul>
    </div>
  </el-form>
</template>

<script>
import { validateChineseCharactersWithSpace, validateEmail } from '@/util/validators';

export default {
  data() {
    return {
      form: {
        accountName: '',
        email: ''
      },
      rules: {
        accountName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.alipay.field.accountName')
            }),
            trigger: 'blur'
          },
          {
            validator: validateChineseCharactersWithSpace(this.$t('withdraw.formErrorMsg.onlyChineseCharcters')),
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.alipay.field.email')
            }),
            trigger: 'blur'
          },
          {
            validator: validateEmail(this.$t('withdraw.formErrorMsg.emailFormat')),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      return this.$refs.alipayForm.validate();
    }
  }
};
</script>
