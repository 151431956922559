<template>
  <el-form :model="form" label-position="top" :rules="TransferFormRules" ref="withdrawFormChild">
    <div class="form_list_inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.default.selectBA')" prop="selectedCardID">
            <el-select v-model="form.selectedCardID" data-testid="selectedCardID">
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, 2, dropdownTranslationObj)"
                :data-testid="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div v-if="form.selectedCardID || form.selectedCardID === 0">
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.BankName')" prop="bankName">
              <el-select v-if="userCountryCode !== congoCountryCode" v-model="form.bankName" data-testid="bankName">
                <el-option
                  v-for="item in bankList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                  :data-testid="item.label"
                ></el-option>
              </el-select>
              <el-input
                v-else
                maxlength="100"
                type="text"
                v-model="form.bankName"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="bankName"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.BankBeneficiaryName')" prop="beneficiaryName">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.beneficiaryName"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="beneficiaryName"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.BankAccountNumber')" prop="accountNumber">
              <el-input
                maxlength="100"
                type="text"
                v-model.trim="form.accountNumber"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="accountNumber"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.Province')" prop="bankProvince">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.bankProvince"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="bankProvince"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.City')" prop="bankCity">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.bankCity"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="bankCity"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.ImportantNotes')">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.importantNotes"
                auto-complete="off"
                data-testid="importantNotes"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
        <el-checkbox v-model="isRemember" data-testid="remember"> {{ $t('withdraw.default.remember') }}</el-checkbox>
      </div>
    </div>
  </el-form>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';

export default {
  name: 'Africa',
  props: {
    withdrawalType: Number,
    userCountryCode: Number,
    bankList: {
      type: Array,
      default: () => []
    }
  },
  mixins: [withdrawlMixin, bankTransferMixin],
  data() {
    return {
      form: {
        bankName: '',
        bankProvince: '',
        bankCity: '',
        accountNumber: '',
        beneficiaryName: '',
        importantNotes: '',
        userPaymentInfoId: '',
        selectedCardID: null
      },
      TransferFormRules: {
        bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankName'),
            trigger: 'blur'
          }
        ],
        bankProvince: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.province'),
            trigger: 'blur'
          }
        ],
        bankCity: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.city'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccountNumber,
            trigger: 'blur'
          }
        ],
        beneficiaryName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.beneName'),
            trigger: 'blur'
          }
        ]
      },
      isRemember: true,
      congoCountryCode: 7031
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    },
    'form.selectedCardID'(value) {
      const selectedCardInfo = this.availableCards.find(item => item.id === value);
      console.log(selectedCardInfo);
      this.form = {
        ...this.form,
        bankName: selectedCardInfo.bankName || '',
        accountNumber: selectedCardInfo.accountNumber || '',
        beneficiaryName: selectedCardInfo.beneficiaryName || '',
        bankProvince: selectedCardInfo.bankProvince || '',
        bankCity: selectedCardInfo.bankCity || '',
        importantNotes: selectedCardInfo.importantNotes || '',
        countryCode: this.userCountryCode,
        isRememberInfo:
          selectedCardInfo && selectedCardInfo.id !== -1
            ? selectedCardInfo.id
              ? ''
              : this.isRemember
            : this.isRemember,
        userPaymentInfoId: selectedCardInfo && selectedCardInfo.id !== -1 ? selectedCardInfo.id : null
      };
      this.isdisabled = selectedCardInfo && selectedCardInfo.id !== -1 ? true : false;
    },
    'form.accountNumber'(value) {
      this.form.accountNumber = this.latinNumberValidator(value);
    },
    'form.beneficiaryName'(value) {
      this.form.beneficiaryName = this.latinNumberValidator(value);
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormChild.validate();
    }
  }
};
</script>
