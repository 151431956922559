<template>
  <div id="usdc-withdraw">
    <el-form label-position="top" :model="form" ref="form" :rules="rules">
      <el-row :gutter="40">
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('withdraw.USDCEmail')" prop="cryptoWalletAddress">
            <el-popover
              v-if="usdccpsAddress.length > 0"
              placement="bottom"
              width="500"
              trigger="click"
              v-model="visible"
            >
              <el-input
                slot="reference"
                v-model="form.cryptoWalletAddress"
                maxlength="64"
                data-testid="cryptoWalletAddress"
              ></el-input>
              <table>
                <tr v-for="(item, index) in usdccpsAddress" :key="index" @click="chooseAddress(item)">
                  <td style="width: 100px">{{ item.remark }}</td>
                  <td>{{ item.address }}</td>
                </tr>
              </table>
            </el-popover>
            <el-input
              v-if="!(usdccpsAddress.length > 0)"
              v-model="form.cryptoWalletAddress"
              maxlength="64"
              data-testid="cryptoWalletAddress"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('withdraw.ImportantNotes')" prop="importantNotes">
            <el-input v-model="form.importantNotes" data-testid="importantNotes"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('withdraw.enterVerCode')" prop="code">
            <div class="flex">
              <el-input v-model="form.code" data-testid="code"></el-input>
              <el-button :disabled="codeBtnDisabled" class="btn_color" data-testid="sendVerCode" @click="sendCode"
                >{{ $t('withdraw.sendVerCode') }} <span v-if="codeBtnDisabled"> ({{ count }}) </span>
              </el-button>
            </div>
            <p
              class="note"
              v-show="codeBtnDisabled"
              v-html="$t('withdraw.sendVerEmail', { sendEmail: getCryptoEmail(), mail: GLOBAL_CONTACT_EMAIL })"
            ></p>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import cryptoSendVerCode from '@/mixins/withdrawl/cryptoSendVerCode';
export default {
  name: 'usdcWithdraw',
  mixins: [cryptoSendVerCode],
  props: { withdrawalType: Number, usdccpsAddress: Array },
  data() {
    const validateAddress = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('withdraw.formErrorMsg.usdc')));
      } else if (value.includes(' ')) {
        callback(new Error(this.$t('withdraw.formErrorMsg.spaceReq')));
      } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
        callback(new Error(this.$t('withdraw.formErrorMsg.alphReq')));
      } else callback();
    };
    const validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('withdraw.formErrorMsg.invalidCode')));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      form: {
        cryptoWalletAddress: '',
        importantNotes: '',
        addressRemark: '',
        code: ''
      },
      rules: {
        cryptoWalletAddress: [
          {
            required: true,
            validator: validateAddress,
            trigger: 'blur'
          }
        ],
        code: [
          {
            required: true,
            validator: validateCode,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    //点击地址填入
    chooseAddress(options) {
      this.form.cryptoWalletAddress = options.address;
      this.form.addressRemark = options.remark;
      this.visible = false;
    },
    getFormData() {
      return {
        withdrawalType: this.withdrawalType,
        cryptoWalletAddress: this.form.cryptoWalletAddress,
        importantNotes: this.form.importantNotes,
        addressRemark: this.form.addressRemark,
        addressType: 1,
        code: this.form.code,
        txId: sessionStorage.getItem('txId')
      };
    },
    submitForm() {
      return this.$refs.form.validate();
    }
  }
};
</script>
<style lang="scss" scoped>
.btn_color {
  color: black !important;
  & {
    color: black !important;
  }
}
</style>
