<template>
  <el-form :model="form" label-position="top" :rules="TransferFormRules" ref="withdrawFormThailand">
    <div class="form_list_inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.default.selectBA')" prop="selectedCardID">
            <el-select v-model="form.selectedCardID" data-testid="selectedCardID">
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, 2, dropdownTranslationObj)"
                :data-testid="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div v-if="form.selectedCardID || form.selectedCardID === 0">
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.BankName')" prop="bankName">
              <el-input v-if="showBankInput()" v-model="form.bankName" data-testid="bankName"></el-input>
              <el-select v-else v-model="form.bankName" filterable :disabled="isdisabled" data-testid="bankName">
                <el-option
                  v-for="bank in bankList"
                  :key="bank.value"
                  :value="bank.value"
                  :label="langTranslate('withdraw.banklist.', bank.label)"
                  :data-testid="bank.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.AccountNumberIBAN')" prop="accountNumber">
              <el-input
                maxlength="100"
                type="text"
                v-model.trim="form.accountNumber"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="accountNumber"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.BankBeneficiaryName')" prop="beneficiaryName">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.beneficiaryName"
                auto-complete="off"
                :disabled="!isChinaInternetBanking && isdisabled"
                data-testid="beneficiaryName"
                @blur="() => (form.beneficiaryName = form.beneficiaryName.trim())"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.ImportantNotes')">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.importantNotes"
                auto-complete="off"
                data-testid="importantNotes"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>

      <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
        <el-checkbox v-model="isRemember" data-testid="remember"> {{ $t('withdraw.default.remember') }}</el-checkbox>
      </div>
    </div>
  </el-form>
</template>

<script>
import { validateChineseCharactersWithSpace } from '@/util/validators';
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';

export default {
  props: ['withdrawalType', 'bankList'],
  mixins: [bankTransferMixin, withdrawlMixin],
  data() {
    return {
      form: {
        bankName: '',
        bankAddress: '',
        accountNumber: '',
        beneficiaryName: '',
        swift: '',
        importantNotes: '',
        userPaymentInfoId: '',
        selectedCardID: null
      },
      isRemember: true,
      TransferFormRules: {
        bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankName'),
            trigger: 'blur'
          },
          {
            validator: this.validateNoSymbol,
            trigger: 'blur'
          }
        ],
        bankAddress: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankAddress'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccountNumber,
            trigger: 'blur'
          }
        ],
        beneficiaryName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.beneName'),
            trigger: 'blur'
          }
        ],
        swift: [{ required: true, message: this.$t('withdraw.formErrorMsg.swift'), trigger: 'blur' }]
      }
    };
  },
  computed: {
    isChinaInternetBanking() {
      return this.withdrawalType === 104;
    }
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    },
    'form.selectedCardID'(value) {
      let selectedCardInfo = this.availableCards.find(item => item.id === value);
      console.log(selectedCardInfo);
      if (selectedCardInfo && selectedCardInfo.id !== -1) {
        this.form = {
          ...this.form,
          bankName: selectedCardInfo.bankName,
          bankAddress: selectedCardInfo.bankAddress,
          accountNumber: selectedCardInfo.accountNumber,
          beneficiaryName: selectedCardInfo.beneficiaryName,
          importantNotes: selectedCardInfo.importantNotes,
          swift: selectedCardInfo.swift,
          isRememberInfo: selectedCardInfo.id ? '' : this.isRemember,
          userPaymentInfoId: selectedCardInfo.id ? selectedCardInfo.id : ''
        };
        this.isdisabled = true;
      } else {
        //choose another
        this.form = {
          ...this.form,
          bankName: '',
          bankAddress: '',
          accountNumber: '',
          beneficiaryName: '',
          importantNotes: '',
          swift: '',
          userPaymentInfoId: '',
          isRememberInfo: this.isRemember
        };
        this.isdisabled = false;
      }
    },
    'form.accountNumber'(value) {
      this.form.accountNumber = this.latinNumberValidator(value);
    },
    'form.beneficiaryName'(value) {
      this.form.beneficiaryName = this.isChinaInternetBanking ? value : this.latinNumberValidator(value);
    },
    isChinaInternetBanking: {
      handler(isChinaInternetBanking) {
        const chineseCharacterRuleId = 'validateChineseCharactersWithSpace';

        if (isChinaInternetBanking) {
          this.TransferFormRules.beneficiaryName = [
            ...this.TransferFormRules.beneficiaryName,
            {
              id: chineseCharacterRuleId,
              validator: validateChineseCharactersWithSpace(this.$t('withdraw.formErrorMsg.onlyChineseCharcters')),
              trigger: ['blur']
            }
          ];
        } else {
          const validateChineseCharactersWithSpaceRule = this.TransferFormRules.beneficiaryName.find(
            e => e.id === chineseCharacterRuleId
          );

          if (validateChineseCharactersWithSpaceRule) {
            this.TransferFormRules.beneficiaryName = this.TransferFormRules.beneficiaryName.filter(
              e => e.id !== chineseCharacterRuleId
            );
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormThailand.validate();
    },
    showBankInput() {
      return this.isChinaInternetBanking;
    },
    validateNoSymbol(rule, value, callback) {
      if (this.isChinaInternetBanking) {
        const reg = /^[a-zA-Z0-9\u4E00-\u9FFF\s]+$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t('common.formValidation.noSymbolAllowed')));
        } else {
          callback();
        }
      } else {
        callback();
      }
    }
  }
};
</script>
