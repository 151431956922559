<template>
  <Card>
    <template v-slot:header>
      <span>{{ header }}</span>
      <em class="el-icon-warning-outline text-primary-alt"></em>
    </template>
    <div class="mb-3">{{ content }}</div>
    <el-button @click="confirm" data-testid="confirm">{{ btnText }} </el-button>
  </Card>
</template>
<script>
import Card from '@/components/Card';

export default {
  name: 'PendingAction',
  components: { Card },
  props: { type: String },
  computed: {
    header() {
      return this.$t('identityProof.home.header1');
    },
    content() {
      return this.$t('identityProof.home.content1', {
        name: this.$store.state.common.userName
      });
    },
    btnText() {
      return this.$t('common.button.identityProof');
    }
  },
  methods: {
    confirm() {
      this.$emit('clickedPendingAction', this.type);
    }
  }
};
</script>
