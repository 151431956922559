<template>
  <el-form :model="form" label-position="top" :rules="rules" ref="withdrawForm">
    <div class="form_list_inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.default.selectBA')" prop="selectedCardID">
            <el-select v-model="form.selectedCardID" data-testid="selectedCardID">
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, 2, dropdownTranslationObj)"
                :data-testid="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div v-if="form.selectedCardID || form.selectedCardID === 0">
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <!-- BANK NAME -->
          <li>
            <el-form-item :label="$t('withdraw.BankName')" prop="bankName">
              <el-select v-model="form.bankName" filterable :disabled="isdisabled" data-testid="bankName">
                <el-option
                  v-for="item in bankList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                  :data-testid="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </li>

          <!-- BANK ACCOUNT NAME -->
          <li>
            <el-form-item :label="$t('withdraw.BankAccountName')" prop="bankAccountName">
              <i class="el-icon-info label_icon text-primary-alt" @click="openPdf()"></i>
              <el-input v-model="form.bankAccountName" :disabled="isdisabled" data-testid="bankAccountName"></el-input>
            </el-form-item>
          </li>

          <!-- ACCOUNT NUMBER -->
          <li>
            <el-form-item :label="$t('withdraw.BankAccountNumber')" prop="accountNumber">
              <el-input
                maxlength="100"
                type="text"
                v-model.trim="form.accountNumber"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="accountNumber"
              ></el-input>
            </el-form-item>
          </li>

          <!-- BANK BRANCH CODE -->
          <li>
            <el-form-item :label="$t('withdraw.BankBranch')" prop="bankBranchCode">
              <el-input
                maxlength="100"
                type="text"
                v-model.trim="form.bankBranchCode"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="bankBranchCode"
              ></el-input>
            </el-form-item>
          </li>

          <!-- ACCOUNT TYPE -->
          <li>
            <el-form-item :label="$t('withdraw.BankAccountType')" prop="bankAccType">
              <el-select v-model="form.bankAccType" filterable :disabled="isdisabled" data-testid="bankAccType">
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                  :data-testid="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </li>

          <!-- IMPORTANT NOTES -->
          <li>
            <el-form-item :label="$t('withdraw.ImportantNotes')">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.importantNotes"
                auto-complete="off"
                data-testid="importantNotes"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
        <el-checkbox v-model="isRemember" data-testid="remember"> {{ $t('withdraw.default.remember') }}</el-checkbox>
      </div>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24">
          <p class="disclaimer">{{ $t('withdraw.warning.desc') }}</p>
          <ul class="disclaimer">
            <li>{{ $t('withdraw.warning.desc1') }}</li>
            <li>{{ $t('withdraw.warning.desc2') }}</li>
          </ul></el-col
        >
      </el-row>
    </div>
  </el-form>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import { apiDownloadCertificationPDF } from '@/resource';
import pdfHandler from '@/util/pdfHandler';

export default {
  props: ['withdrawalType', 'bankList'],
  mixins: [withdrawlMixin, bankTransferMixin],
  data() {
    return {
      form: {
        bankName: '',
        bankAccountName: '',
        accountNumber: '',
        bankBranchCode: '',
        bankAccType: '',
        importantNotes: '',
        userPaymentInfoId: '',
        selectedCardID: null
      },
      isRemember: true,
      rules: {
        bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankName'),
            trigger: 'blur'
          }
        ],
        bankAccountName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.accName'),
            trigger: ['blur', 'change']
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccountNumber,
            trigger: 'blur'
          }
        ],
        bankBranchCode: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankBranchCodeReq'),
            trigger: ['blur', 'change']
          }
        ],
        bankAccType: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankAccType'),
            trigger: ['blur', 'change']
          }
        ]
      },
      typeList: [
        {
          label: 'Futsu Yokin',
          value: 'Ordinary'
        },
        {
          label: 'Touza Yokin',
          value: 'Current'
        },
        {
          label: 'Chochiku Yokin',
          value: 'Saving'
        },
        {
          label: 'Others',
          value: 'Others'
        }
      ]
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    },
    'form.selectedCardID'(value) {
      let selectedCardInfo = this.availableCards.find(item => item.id === value);
      console.log(selectedCardInfo);
      if (selectedCardInfo && selectedCardInfo.id !== -1) {
        this.form = {
          ...this.form,
          bankName: selectedCardInfo.bankName,
          bankAccountName: selectedCardInfo.bankAccountName,
          accountNumber: selectedCardInfo.accountNumber,
          bankBranchCode: selectedCardInfo.bankBranchCode,
          bankAccType: selectedCardInfo.bankAccType,
          importantNotes: selectedCardInfo.importantNotes,
          isRememberInfo: selectedCardInfo.id ? '' : this.isRemember,
          userPaymentInfoId: selectedCardInfo.id ? selectedCardInfo.id : ''
        };
        this.isdisabled = true;
      } else {
        //choose another
        this.form = {
          ...this.form,
          bankName: '',
          bankAccountName: '',
          accountNumber: '',
          bankBranchCode: '',
          bankAccType: '',
          importantNotes: '',
          userPaymentInfoId: '',
          isRememberInfo: this.isRemember
        };
        this.isdisabled = false;
      }
    },
    'form.accountNumber'(value) {
      this.form.accountNumber = this.latinNumberValidator(value);
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawForm.validate();
    },
    async openPdf() {
      let resp = await apiDownloadCertificationPDF();

      if (resp.data.code == 0) {
        const filename = `banking-information.pdf`;
        pdfHandler(resp.data.data, filename);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/css/pages/zotapayWithdraw.scss';
</style>
