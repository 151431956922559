<template>
  <el-form :model="form" label-position="top" :rules="rules" ref="withdrawForm">
    <div class="form_list_inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.vpa')" prop="vpa">
            <el-input maxlength="100" type="text" v-model="form.vpa" auto-complete="off" data-testid="vpa"></el-input>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.name')" prop="upiName">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.upiName"
              auto-complete="off"
              data-testid="upiName"
            ></el-input>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div class="form_list_inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.email')" prop="upiEmail">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.upiEmail"
              auto-complete="off"
              data-testid="upiEmail"
            ></el-input>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.phone')" prop="upiPhoneNo">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.upiPhoneNo"
              auto-complete="off"
              data-testid="upiPhoneNo"
            ></el-input>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div class="form_list_inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.address')" prop="upiAddress">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.upiAddress"
              auto-complete="off"
              data-testid="upiAddress"
            ></el-input>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.ImportantNotes')">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.importantNotes"
              auto-complete="off"
              data-testid="importantNotes"
            ></el-input>
          </el-form-item>
        </li>
      </ul>
    </div>
  </el-form>
</template>

<script>
import { validateEmail } from '@/util/validators';

export default {
  data() {
    return {
      form: {
        vpa: '',
        upiName: '',
        upiEmail: '',
        upiPhoneNo: '',
        upiAddress: '',
        importantNotes: ''
      },
      rules: {
        vpa: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.vpa')
            }),
            trigger: 'blur'
          }
        ],
        upiName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.name')
            }),
            trigger: 'blur'
          }
        ],
        upiEmail: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.email')
            }),
            trigger: 'blur'
          },
          {
            validator: validateEmail(this.$t('withdraw.formErrorMsg.emailFormat')),
            trigger: 'blur'
          }
        ],
        upiPhoneNo: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.phone')
            }),
            trigger: 'blur'
          }
        ],
        upiAddress: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.address')
            }),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawForm.validate();
    }
  }
};
</script>
