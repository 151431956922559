<template>
  <el-dialog
    :visible="visible"
    :top="top"
    :width="width"
    @close="close"
    :append-to-body="needToBody"
    :close-on-click-modal="enableCloseOnBackground"
  >
    <div slot="title"><slot name="header"></slot></div>
    <div class="dialog_body"><slot></slot></div>
    <div v-if="visibleFooter" slot="footer" class="dialog_footer">
      <ul
        @click="showLiveChat"
        class="cursor-pointer d-inline-flex align-items-center justify-content-end"
        data-testid="liveChat"
      >
        <div v-html="$t('common.liveChat.desc')"></div>
        <div class="ms-2" :class="{ ar_chat: lang === 'ar' }">
          <img src="@/assets/uploads/chat.png" alt />
        </div>
      </ul>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'HomeDialog',
  props: {
    visible: Boolean,
    visibleFooter: Boolean,
    needToBody: { type: Boolean, default: false },
    enableCloseOnBackground: { type: Boolean, default: true },
    top: { type: String, default: '30px' },
    width: { type: String, default: '600px' }
  },
  methods: {
    close() {
      this.$emit('update:visible', false);
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  }
};
</script>

<style lang="scss" scoped>
.ar_chat {
  transform: rotateY(180deg);
}
</style>
