<template>
  <el-form :model="form" label-position="top" :rules="rulesUnionPay" ref="withdrawFormUnionPay">
    <div class="form_list_inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.BankName')" prop="bankName">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.bankName"
              auto-complete="off"
              data-testid="bankName"
            ></el-input>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.BankAccountNumber')" prop="accountNumber">
            <el-input
              maxlength="100"
              type="text"
              v-model.trim="form.accountNumber"
              auto-complete="off"
              data-testid="accountNumber"
            ></el-input>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div class="form_list_inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.Province')" prop="bankProvince">
            <el-select v-model="form.bankProvince" @change="provinceChange" data-testid="bankProvince">
              <el-option
                v-for="(item, idx) in chinaProvinces"
                :key="idx"
                :value="item[0]"
                :label="item[1]"
                :data-testid="'bankProvince' + idx"
              ></el-option>
            </el-select>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.City')" prop="bankCity">
            <el-select v-model="form.bankCity" data-testid="bankCity">
              <el-option
                v-for="(item, idx) in chinaCities"
                :key="idx"
                :value="item[0]"
                :label="item[1]"
                :data-testid="'bankCity' + idx"
              ></el-option>
            </el-select>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div class="form_list_inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.BankBranch')" prop="bankBranchName">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.bankBranchName"
              auto-complete="off"
              data-testid="bankBranchName"
            ></el-input>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.BankAccountsName')" prop="bankAccountName">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.bankAccountName"
              auto-complete="off"
              data-testid="bankAccountName"
            ></el-input>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div class="form_list_inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.ImportantNotes')">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.importantNotes"
              auto-complete="off"
              data-testid="importantNotes"
            ></el-input>
          </el-form-item>
        </li>
      </ul>
    </div>
  </el-form>
</template>
<script>
import { ChineseDistricts } from '@/util/provinceCity';
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import { apiCardInformation } from '@/resource';

export default {
  mixins: [withdrawlMixin],
  props: ['withdrawalType'],
  data() {
    const validateLang = (rule, value, callback) => {
      const reg = /^[\u4E00-\u9FFF]+$/;
      if (!reg.test(value)) {
        callback(new Error(this.$t('common.formValidation.onlyChinese')));
      } else {
        callback();
      }
    };
    return {
      chinaCities: [],
      form: {
        bankName: '',
        accountNumber: '',
        bankProvince: '',
        bankCity: '',
        bankBranchName: '',
        bankAccountName: '',
        importantNotes: ''
      },
      rulesUnionPay: {
        bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankName'),
            trigger: 'blur'
          },
          {
            validator: validateLang,
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccountNumber,
            trigger: 'blur'
          }
        ],
        bankProvince: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.province'),
            trigger: 'blur'
          }
        ],
        bankCity: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.city'),
            trigger: 'blur'
          }
        ],
        bankBranchName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.branchName'),
            trigger: 'blur'
          }
        ],
        bankAccountName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.accName'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  computed: {
    chinaProvinces() {
      return Object.entries(ChineseDistricts['86']);
    }
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    },
    'form.accountNumber'(value) {
      this.form.accountNumber = this.latinNumberValidator(value);
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormUnionPay.validate();
    },
    provinceChange(provinceID) {
      this.chinaCities = Object.entries(ChineseDistricts[provinceID]);
      this.$set(this.form, 'bankCity', Object.entries(ChineseDistricts[provinceID])[0][0]);
    },
    queryCards() {
      return apiCardInformation(0)
        .then(resp => {
          console.log('resp', resp);
          if (resp.data.code == 0) {
            return resp.data.data;
          } else {
            return [];
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  mounted() {
    this.queryCards().then(cards => {
      console.log(209, cards);
      if (cards == null || cards.length == 0) {
        console.log(211, 0);
        //display a warn dialog and redirect to client portal payment details
        this.$emit('update:dialog', {
          show: true,
          text: this.$t('withdraw.noCardWarn'),
          method: 'noCard'
        });
      } else {
        console.log(219, 1);
        this.cards = cards;
        this.cards.forEach(card => {
          card.id = card.card_number;
          card.showNumber =
            card.card_number.substr(0, 6) + '******' + card.card_number.substr(card.card_number.length - 4);
        });
        this.form.bankName = cards[0].bank_name;
        this.form.bankBranchName = cards[0].branch_name;
        this.form.accountShowNumber = cards[0].showNumber;
        this.form.bankAccountName = cards[0].card_holder_name;
        this.form.accountNumber = cards[0].card_number;
        this.form.bankCard = cards[0].card_number;
      }
    });
  }
};
</script>
