<template>
  <div id="block" class="block">
    <div class="top">
      <div class="fll">
        <div class="span" v-html="span"></div>
      </div>
      <div class="frr">
        <img class="rightImg" src="@/assets/uploads/background.png" alt="background" />
        <img class="proofOfAddress" src="@/assets/uploads/proofOfAddress.png" alt="poa" />
      </div>
    </div>
    <div class="middle red">
      <span
        v-if="customPendingReason || PendingReason"
        v-html="$t(`identityProof.reason`, { reason: customPendingReason ? customPendingReason : PendingReason })"
      >
      </span>
    </div>
    <div class="bottom">
      <el-form label-position="top" status-icon>
        <vUpload :limit="limit" v-on:updateFileInfo="updateFileInfo"> </vUpload>
      </el-form>
    </div>
  </div>
</template>

<script>
import vUpload from '@/components/vUpload';

export default {
  props: {
    method: String,
    limit: Number,
    PendingReason: String,
    customPendingReason: String
  },
  components: { vUpload },
  computed: {
    span() {
      return this.$t('identityProof.popup.addressProofSpan');
    }
  },
  methods: {
    updateFileInfo(fileInfo) {
      this.$emit('syncFile', fileInfo.fileList);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/uploadIdOrAddress/block.scss';
</style>
