<template>
  <el-dialog
    :style="cssProps"
    :title="title"
    :customClass="customClass"
    :top="top"
    :width="width"
    :visible.sync="synShowDialog"
    custom-class="vDialog"
    :close-on-click-modal="closeOnClickModal"
    :close-on-press-escape="false"
  >
    <div slot="title"><slot name="title"></slot></div>
    <slot></slot>
    <div slot="footer" class="dialog-footer"><slot name="footer"></slot></div>
  </el-dialog>
</template>

<script>
export default {
  name: 'vDialog',
  props: {
    show: Boolean,
    top: { type: String, default: '30px' },
    width: { type: String, default: '600px' },
    title: { type: String, default: '' },
    bodyPadding: { type: String },
    closeOnClickModal: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    cssProps() {
      return {
        '--bodyPadding': this.bodyPadding || '35px 25px',
        '--fontSize': this.fontSize || '14px'
      };
    },
    synShowDialog: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('update:show', val);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  .el-dialog__body {
    padding: var(--bodyPadding);
  }

  &.withdrawal-freeze-dialog {
    background: #1e1e1e;
  }
}
</style>
