<template>
  <el-form :model="form" label-position="top" :rules="TransferFormRules" ref="withdrawForm">
    <div class="form_list_inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.default.selectBA')" prop="selectedCardID">
            <el-select v-model="form.selectedCardID" data-testid="selectedCardID">
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, 2, dropdownTranslationObj)"
                :data-testid="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div v-if="form.selectedCardID || form.selectedCardID === 0">

       <el-row :gutter="20">
          <el-col :xs="24" :sm="8">
             <el-form-item :label="$t('withdraw.bankCode')" prop="bankName">
              <el-select
                v-model="form.bankName"
                :disabled="isdisabled"
                @change="handleBankCodeChange"
              >
               <el-option
                v-for="bank in banks"
                :key="bank.bankCode"
                :value="bank.value"
              ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('withdraw.bankBranchCode')" prop="bankBranchName">
              <el-select
                v-model="form.bankBranchName"
                :disabled="isdisabled"
              >
               <el-option
                  v-for="bankBranch in bankBranches"
                  :key="bankBranch.branchCode"
                  :value="bankBranch.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
           <el-col :xs="24" :sm="8">
              <el-form-item :label="$t('withdraw.BankAccountsName')" prop="bankAccName">
                <el-input el-input v-model="form.bankAccName" :disabled="isdisabled" data-testid="BankAccountsName"></el-input>
              </el-form-item>
          </el-col>
        </el-row>

          <el-row :gutter="20" type="flex" class="row-bg" justify="space-between">

          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('withdraw.BankAccountNumber')" prop="accountNumber">
              <el-input el-input v-model.trim="form.accountNumber" :disabled="isdisabled" data-testid="accountNumber"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="8" v-if="currentCurrency === 'USD' || currentCurrency === 'USC'">
            <el-form-item :label="$t('withdraw.bankAccountCurrency')" prop="withdrawCurrency">
              <el-select v-model="form.withdrawCurrency" data-testid="withdrawCurrency">
                <el-option
                  v-for="currency in supportedCurrencies"
                  :key="currency"
                  :value="currency"
                  :label="currency"
                  :data-testid="currency"
                ></el-option>
              </el-select>
            </el-form-item>
            <div class="mt-n3 mb-4" v-if="isHKD">
              {{ $t('rate.rate', { oldCurrency: 'USD', newCurrency: 'HKD' }) }}
              <span>{{ rate }}</span>
            </div>
          </el-col>
          <el-col :xs="24" :sm="currentCurrency === 'USD' || currentCurrency === 'USC' ? 8 : 16">
            <el-form-item :label="$t('withdraw.ImportantNotes')" prop="importantNotes">
              <el-input v-model="form.importantNotes" data-testid="importantNotes"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

         <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="24" v-if="!isdisabled">
            <el-form-item :label="$t('withdraw.transfer.label.upload')" class="upload" prop="uploadedFile">
              <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo" data-testid="uploadedFile"></vUpload>
            </el-form-item>
          </el-col>
        </el-row>

      <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
        <el-checkbox v-model="isRememberInfo" data-testid="remember"> {{ $t('withdraw.default.remember') }}</el-checkbox>
      </div>
      
    </div>
  </el-form>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import vUpload from '@/components/vUpload';
import { apiWithdrawalQueryRateUSDByTargetCurrency,apiGetWithdrawalBankCode,apiGetWithdrawalBankBranchCode } from '@/resource';

export default {  
  name: 'HongKongWithdraw',
  props: ['withdrawalType', 'bankList','currentCurrency'],
  mixins: [bankTransferMixin, withdrawlMixin],
  components: { vUpload },
  data() {
    const validateFile = (rule, value, callback) => {
      if (this.fileList.length < 1) callback(new Error(this.$t('withdraw.transfer.formValidation.upload')));
      else callback();
    };
    return {
      form: {
        bankName: '',
        bankBranchName:'',
        bankAccName: '',
        accountNumber: '',
        importantNotes: '',
        userPaymentInfoId: null,
        withdrawCurrency: 'USD',
        selectedCardID: null
      },
      banks:[],
      bankBranches:[],
      isHKD: false,
      rate: 7.75,
      supportedCurrencies: ['HKD', 'USD'],
      isRememberInfo: true,
      fileList: [],
      TransferFormRules: {
         bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankCodeReq'),
            trigger: ['blur', 'change']
          }
        ],
        bankBranchName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankBranchCodeReq'),
            trigger: ['blur', 'change']
          }
        ],
        bankAccName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', { fieldname: this.$t('withdraw.BankAccountsName') }),
            trigger: ['blur', 'change']
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccountNumber,
            trigger: ['blur', 'change']
          }
        ],
        withdrawCurrency: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.bankAccountCurrency')
            }),
            trigger: 'change'
          }
        ],
        uploadedFile: [
          {
            required: true,
            validator: validateFile,
            trigger: 'change'
          }
        ]
      }
    };
  },
  watch: {
    'form.withdrawCurrency'(value) {
      if (value === 'HKD') this.isHKD = true;
      else this.isHKD = false;
    },
    'form.selectedCardID'(value) {
      const selectedCardInfo = this.availableCards.find(item => item.id === value);
      const isCurrentCard = selectedCardInfo && selectedCardInfo.id !== -1;

      console.log(selectedCardInfo);
      this.form = {
        selectedCardID: value,
        bankName: selectedCardInfo.bankName || '',
        bankBranchName: selectedCardInfo.bankBranchName||'',
        bankAccName: selectedCardInfo.bankAccountName || '',
        accountNumber: selectedCardInfo.accountNumber || '',
        importantNotes: selectedCardInfo.importantNotes || '',
        userPaymentInfoId: isCurrentCard ? selectedCardInfo.id : null,
        withdrawCurrency: selectedCardInfo.withdrawCurrency || this.currentCurrency === 'USD' ? '' : 'HKD'
      };
      this.isdisabled = isCurrentCard ? true : false;
    },
   currentCurrency(value) {
      console.log('currentCurrency:',value)
    },
  },
  methods: {
     queryRate() {
      apiWithdrawalQueryRateUSDByTargetCurrency('HKD')
        .then(resp => {
          if (resp.data.code == 0) this.rate = resp.data.data;
        })
        .catch(err => {
          this.errorMessage(
            this.$t('deposit.default.rate.result.rateError', { oldCurrency: 'USD', newCurrency: 'HKD' })
          );
        });
    },
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['withdrawForm'].validateField('uploadedFile');
    },
     getFormData() {
      return {
        withdrawalType: this.withdrawalType,
        bankName: this.form.bankName,
        bankAccountName: this.form.bankAccName,
        accountNumber: this.form.accountNumber,
        bankBranchName: this.form.bankBranchName,
        importantNotes: this.form.importantNotes,
        isRememberInfo: this.form.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.form.userPaymentInfoId ? this.form.userPaymentInfoId : '',
        targetCurrency: this.form.withdrawCurrency,
        fileList: this.fileList
      };
    },
     queryBankCode() {
      apiGetWithdrawalBankCode ()
        .then(resp => {
          if (resp.data.code == 0) this.banks = resp.data.data.map(bank=>({...bank,value:bank.bankCode + ' - ' + bank.bankName}));
        })
        .catch(err => {
          this.errorMessage(
            this.$t('Get Bank Code Error.')
          );
        });
    },
     queryBankBranchCode(bankCode) {
      apiGetWithdrawalBankBranchCode(bankCode)
        .then(resp => {
          if (resp.data.code == 0) this.bankBranches = resp.data.data.map(bank=>({...bank,value:bank.branchCode + ' - ' + bank.branchName}));
        })
        .catch(err => {
          this.errorMessage(
            this.$t('Get Bank Branch Code Error.')
          );
        });
    },
    handleBankCodeChange(value){
     
      let currentBankCode = this.banks.find(bank=>bank.value === value).bankCode;
      this.queryBankBranchCode(currentBankCode); 
      this.form.bankBranchName = '';
    },
    submitForm() {
      return this.$refs.withdrawForm.validate();
    }
  },
  mounted() {
    this.queryRate();
    this.queryBankCode();
  }
};
</script>
