<template>
  <div id="laos" class="bankForm_wrapper">
    <el-form label-position="top" :model="form" ref="form" :rules="rules">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="11" class="label_wrapper">
          <div class="label fl">{{ $t('common.withdrawChannel.banktransfer') }}</div>
          <el-form-item prop="selectedCardID" class="fr">
            <el-select v-model="form.selectedCardID" data-testid="selectedCardID">
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, withdrawalType, dropdownTranslationObj, that)"
                :data-testid="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="11" class="label_wrapper">
          <div class="label fl2 label_margin-left">{{ $t('common.field.amt') }}</div>
          <el-form-item prop="availableAmount" class="fr2">
            <el-input data-testid="availableAmount" v-model="amount" disabled></el-input> </el-form-item
        ></el-col>
      </el-row>
      <div v-if="form.selectedCardID || form.selectedCardID === 0">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bankName')" prop="bankName" required>
              <el-select v-model="form.bankName" :disabled="isdisabled" data-testid="bankName">
                <el-option
                  v-for="item in bankList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                  :data-testid="item.label"
                ></el-option>
              </el-select>
              <p>{{ $t('common.withdrawChannel.withdrawalBankMsg') }}</p>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bankBeneficiaryName')" prop="beneficiaryName">
              <el-input v-model="form.beneficiaryName" :disabled="isdisabled" data-testid="beneficiaryName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bankAccNum')" prop="accountNumber">
              <el-input v-model.trim="form.accountNumber" :disabled="isdisabled" data-testid="accountNumber"></el-input>
            </el-form-item>
          </el-col>
        </el-row>


        <el-row :gutter="20">
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
              <el-input v-model="form.importantNotes" data-testid="importantNotes"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24" :sm="8" v-if="!isdisabled">
            <el-form-item :label="$t('withdraw.transfer.label.upload')" class="upload" prop="uploadedFile">
              <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo" data-testid="uploadedFile"></vUpload>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
          <el-checkbox v-model="isRememberInfo" data-testid="checkbox">
            {{ $t('withdraw.default.remember') }}</el-checkbox
          >
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import vUpload from '@/components/vUpload';

export default {
  name: 'LaosWithdraw',
  components: { vUpload },
  props: {

    withdrawalType: Number,
    bankList: {
      type: Array,
      default: () => []
    }
  },
  mixins: [bankTransferMixin, withdrawlMixin],
  data() {
    return {
      that: this,
      form: {
        bankName: '',
        bankAddress: '',
        swift: '',
        beneficiaryName: '',
        accountNumber: '',
        bankProvince: '',
        bankCity: '',
        importantNotes: '',
        userPaymentInfoId: null
      },
      rules: {
        bankName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),
            trigger: ['blur', 'change']
          }
        ],
        bankAddress: [
          {
            required: true,
            message: this.$t('common.formValidation.bankAddressReq'),
            trigger: ['blur', 'change']
          }
        ],
        swift: [
          {
            required: true,
            message: this.$t('common.formValidation.swiftReq'),
            trigger: ['blur', 'change']
          }
        ],
        beneficiaryName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankBeneficiaryNameReq'),
            trigger: ['blur', 'change']
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccount,
            trigger: ['blur', 'change']
          }
        ],
        bankProvince: [
          {
            required: true,
            message: this.$t('common.formValidation.bankProvinceReq'),
            trigger: ['blur', 'change']
          }
        ],
        bankCity: [
          {
            required: true,
            message: this.$t('common.formValidation.bankCityReq'),
            trigger: ['blur', 'change']
          }
        ],
        fileList: []
      }
    };
  },
  methods: {
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['form'].validateField('uploadedFile');
    },
    getFormData() {
      return {

        withdrawalType: this.withdrawalType,
        bankName: this.form.bankName,
        bankAddress: this.form.bankAddress,
        beneficiaryName: this.form.beneficiaryName,
        accountNumber: this.form.accountNumber,
        bankProvince: this.form.bankProvince,
        bankCity: this.form.bankCity,
        importantNotes: this.form.importantNotes,
        isRememberInfo: this.form.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.form.userPaymentInfoId ? this.form.userPaymentInfoId : ''
      };
    }
  },
  watch: {
    'form.selectedCardID'(value) {
      const selectedCardInfo = this.availableCards.find(item => item.id === value);
      const isCurrentCard = selectedCardInfo && selectedCardInfo.id !== -1;

      console.log(selectedCardInfo);
      this.form = {
        selectedCardID: value,
        bankName: selectedCardInfo.bankName || '',
        bankAddress: selectedCardInfo.bankAddress || '',
        beneficiaryName: selectedCardInfo.beneficiaryName || '',
        accountNumber: selectedCardInfo.accountNumber || '',
        bankProvince: selectedCardInfo.bankProvince || '',
        bankCity: selectedCardInfo.bankCity || '',
        importantNotes: selectedCardInfo.importantNotes || '',
        userPaymentInfoId: isCurrentCard ? selectedCardInfo.id : null
      };
      this.isdisabled = isCurrentCard ? true : false;
    },
    'form.accountNumber'(value) {
      this.form.accountNumber = this.latinNumberValidator(value);
    }
  }
};
</script>
