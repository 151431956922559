<template>
  <div class="idPoa">
    <PendingAction
      v-if="showIdentityProofAlert"
      :type="'identityProof'"
      v-on:clickedPendingAction="pendingActionResponse"
    ></PendingAction>

    <Notification v-if="showNotification" :poa="pendingAddressProofApproval"></Notification>
    <UploadIdOrAddress
      :visible.sync="uploadIdOrAddress.visible"
      :poaPendingReason="poaPendingReason"
      :poaCustomPendingReason="poaCustomPendingReason"
      :poaRecode="poaRecode"
    ></UploadIdOrAddress>
  </div>
</template>

<script>
import PendingAction from '@/components/home/PendingAction';
import Notification from '@/components/home/Notification';
import UploadIdOrAddress from '@/components/home/UploadIdAddress/UploadIdOrAddress';
import idPoaMixin from '@/mixins/idPoa';

export default {
  name: 'IdPoa',
  components: { PendingAction, Notification, UploadIdOrAddress },
  props: { showNotification: Boolean },
  mixins: [idPoaMixin],
  mounted() {
    this.getPoa();
  },
  data() {
    return {
      uploadIdOrAddress: {
        visible: false
      }
    };
  },
  methods: {
    pendingActionResponse(type) {
      this.uploadIdOrAddress.visible = true;
    }
  }
};
</script>
