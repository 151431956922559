<template>
  <div>
    <p class="mb-1">{{ $t('fileUploader.supportedFormatCustom', { files: allowedFile.join(', ') }) }}</p>
    <p class="mb-1">{{ $t('fileUploader.maxSize', { maxSize: maxSize }) }},</p>
    <p>{{ $t('fileUploader.maxFileNum', { maxFileNum: uploadLimit }) }}</p>
  </div>
</template>
<script>
export default {
  name: 'vUpload',
  props: {
    maxSize: {
      type: Number,
      default: 5
    },
    uploadLimit: {
      type: Number,
      default: 1
    },
    allowedFile: {
      type: Array,
      default: function() {
        return ['png', 'jpg', 'jpeg', 'bmp', 'pdf', 'doc', 'docx'];
      }
    }
  }
};
</script>
