<template>
  <div>
    <vDialog :show.sync="visible" customClass="withdrawal-freeze-dialog">
      <div class="text-center">
        <div class="dialog-title">{{ $t('withdrawalFreeze.header') }}</div>
        <div v-if="fromFunctionCode === 'updatePassword'" class="dialog-desc">
          {{ $t('withdrawalFreeze.desc', { day: day, hour: hour }) }}
        </div>
        <div v-else class="dialog-desc">{{ $t('withdrawalFreeze.desc2', { day: day, hour: hour }) }}</div>
      </div>
      <div slot="footer">
        <div class="d-flex justify-content-between">
          <el-button class="w-100 yellow-btn" @click="handleClose">{{ $t('common.button.iunderstand') }}</el-button>
        </div>
      </div>
    </vDialog>
  </div>
</template>

<script>
import vDialog from '@/components/vDialog';

export default {
  name: 'WithdrawalFreezeDialog',
  components: {
    vDialog
  },
  props: {
    freezeDialogVisible: {
      type: Boolean,
      default: false
    },
    fromFunctionCode: {
      type: String,
      default: ''
    },
    day: {
      type: Number,
      default: 0
    },
    hour: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    freezeDialogVisible(val) {
      this.visible = val;
    },
    visible(val) {
      this.$emit('update:freezeDialogVisible', val);
    },
    day(val) {
      this.day = val;
    },
    hour(val) {
      this.hour = val;
    }
  },
  methods: {
    handleClose() {
      this.visible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #ffffff;
}

.dialog-desc {
  color: #ffffff;
}

.yellow-btn {
  background: #ffcd05;
  border-color: #ffcd05;

  &:hover {
    background: #fdda4e;
    border-color: #fdda4e;
  }
}
</style>
