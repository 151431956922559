<template>
  <div id="rebateWithdraw">
    <div class="wrapper_main">
      <div class="wrapper_content" v-if="idPoaPending || showIdentityProofAlert">
        <idPoa :showNotification="idPoaPending"></idPoa>
      </div>
      <div class="wrapper_content" v-else>
        <h2>{{ $t('withdraw.REBATE_WITHDRAW_REQUEST') }}</h2>
        <div class="form_container">
          <Top
            ref="top"
            :chooseWithdrawalType="chooseWithdrawalType"
            @withdrawFormChange="withdrawFormChange"
            @setCountryCode="getCountryCode"
            @setBankList="getBankList"
          ></Top>
          <component
            :is="currentComponent"
            :userCountryCode="userCountryCode"
            :bankList="withdrawalBankList"
            :withdrawalType="withdrawForm.withdrawalType"
            :chooseWithdrawalType="chooseWithdrawalType"
            :currentCurrency="withdrawForm.currency"
            @formChange="formChange"
            @changeBankComponent="countryAUandIntel"
            ref="child"
            :dialog.sync="dialog"
            :ethAddress="ethAddress"
            :usdccpsAddress="usdccpsAddress"
          ></component>
          <p
            class="note"
            v-if="$refs.top && $refs.top.showNote"
            v-html="$t('withdraw.note', { mail: GLOBAL_CONTACT_EMAIL })"
          ></p>
          <p
            class="note"
            v-if="checkMethodMinAmount && belowMinimumAmount"
            v-html="$t('withdraw.minAmountWarnNoLink', { regulatorUrl: GLOBAL_DOMAIN_WEBSITE })"
          ></p>
        </div>
        <div class="text-center btn">
          <el-button @click="submitForm('withdrawForm')" :disabled="hasNegativeBalance" data-testid="submit">{{
            $t('common.keys.SUBMIT')
          }}</el-button>
        </div>
      </div>
    </div>
    <vDialog :visible.sync="dialog.show" top="15%" class="my_dialog">
      <p>{{ dialog.text }}</p>
      <div class="form_button" v-if="dialog.method === 'noCard'">
        <el-button @click="confirm" data-testid="confirm">
          {{ $t('common.button.confirm') }}
        </el-button>
      </div>
    </vDialog>
    <WithdrawalFreezeDialog
      :freezeDialogVisible.sync="freezeDialogVisible"
      :fromFunctionCode="fromFunctionCode"
      :day="day"
      :hour="hour"
    >
    </WithdrawalFreezeDialog>
  </div>
</template>

<script>
import Top from '@/components/rebateWithdraw/Top';
import Skrill from '@/components/rebateWithdraw/Skrill';
import Neteller from '@/components/rebateWithdraw/Neteller';
import UnionPay from '@/components/rebateWithdraw/UnionPay';
import Fasapay from '@/components/rebateWithdraw/Fasapay';
import BankTransfer from '@/components/rebateWithdraw/BankTransfer';
import Thailand from '@/components/rebateWithdraw/Thailand';
import Malaysia from '@/components/rebateWithdraw/Malaysia';
import Bitwallet from '@/components/rebateWithdraw/Bitwallet';
import Nigeria from '@/components/rebateWithdraw/Nigeria';
import Vietnam from '@/components/rebateWithdraw/Vietnam';
import Bitcoin from '@/components/rebateWithdraw/Bitcoin';
import USDT from '@/components/rebateWithdraw/USDT';
import Sticpay from '@/components/rebateWithdraw/Sticpay';
import Indonesian from '@/components/rebateWithdraw/Indonesian';
import HongKong from '@/components/rebateWithdraw/HongKong';
import India from '@/components/rebateWithdraw/India';
import PhpAndSA from '@/components/rebateWithdraw/PhpAndSA';
import EftWithdraw from '@/components/rebateWithdraw/EftWithdraw';
import Laos from '@/components/rebateWithdraw/Laos';
import Dasshpe from '@/components/rebateWithdraw/Dasshpe';
import PerfectMoney from '@/components/rebateWithdraw/PerfectMoney';
import Africa from '@/components/rebateWithdraw/Africa';
import MexicoAndBrazil from '@/components/rebateWithdraw/MexicoAndBrazil';

import PlusWalletsWithdraw from '@/components/rebateWithdraw/PlusWalletsWithdraw';
import EbuyWithdraw from '@/components/rebateWithdraw/EbuyWithdraw';
import Alipay from '@/components/rebateWithdraw/Alipay';

import EthWithdraw from '@/components/rebateWithdraw/EthWithdraw';
import UsdcWithdraw from '@/components/rebateWithdraw/UsdcWithdraw';
import LaosWithdraw from '@/components/rebateWithdraw/LaosWithdraw';
import BeetellerWithdraw from '@/components/rebateWithdraw/beetellerWithdraw';
import LaosLocalDepositor from '@/components/rebateWithdraw/LaosLocalDepositor';
// import LaosLocalDepositor from '@/components/rebateWithdraw/LaosLocalDepositor';
import ChileWithdraw from '@/components/rebateWithdraw/ChileWithdraw';
import ZotaPayWithdraw from '@/components/rebateWithdraw/ZotaPayWithdraw.vue';
import cryptoSendVerCode from '@/mixins/withdrawl/cryptoSendVerCode';

import { ChineseDistricts } from '@/util/provinceCity';
import { apiApplyWithdrawal, apiGetSecurity } from '@/resource';
import mixin from '@/mixins';
import idPoa from '@/components/idPoa/Index';
import idPoaMixin from '@/mixins/idPoa';
import vDialog from '@/components/home/vDialog';
import signHelper from '@/util/signinHelper';

import store from '@/store';
import WithdrawalFreezeDialog from '@/components/WithdrawalFreezeDialog';

export default {
  name: 'RebateWithdraw',
  components: { Top, idPoa, vDialog, WithdrawalFreezeDialog },
  mixins: [mixin, idPoaMixin, cryptoSendVerCode],
  data() {
    return {
      dialog: {
        show: false,
        text: '',
        method: ''
      },
      ethAddress: [],
      usdccpsAddress: [],
      userId: this.$store.state.common.CUID,
      accountName: this.$store.state.common.userName,
      country: null,
      form: {},
      bankList: {},
      withdrawalBankList: [],
      withdrawForm: {},
      chooseWithdrawalType: {},
      userCountryCode: null,
      currentComponent: null,
      transferFlag: false,
      prevRoute: null,
      withdrawalTypesMap: {
        2: BankTransfer,
        4: UnionPay,
        5: Thailand,
        6: Malaysia,
        7: Fasapay,
        8: Vietnam,
        9: Nigeria,
        24: India,
        31: Skrill,
        32: Neteller,
        34: Bitcoin,
        36: USDT,
        37: Bitwallet,
        38: Sticpay,
        39: Indonesian,
        40: PhpAndSA,
        42: EftWithdraw,
        47: Laos,
        48: Dasshpe,
        49: HongKong,
        50: Africa,
        51: Africa,
        52: Africa,
        53: Africa,
        54: Africa,
        55: Africa,
        56: Africa,
        57: Africa,
        58: Africa,
        572: Africa,
        61: PerfectMoney,
        63: ChileWithdraw,
        64: MexicoAndBrazil,
        65: LaosLocalDepositor,
        71: Thailand,
        72: ChileWithdraw, //63,72
        75: EbuyWithdraw,
        76: PlusWalletsWithdraw,
        77: PlusWalletsWithdraw,
        80: EthWithdraw,
        81: UsdcWithdraw,
        83: PlusWalletsWithdraw,
        84: PlusWalletsWithdraw, //pluswallets出金
        85: BeetellerWithdraw,
        88: ChileWithdraw, //63,72
        89: ChileWithdraw, //63,72
        90: ChileWithdraw,
        104: Thailand, // Zotapay (China Instant Bank Transfer)
        112: Alipay,
        114: ZotaPayWithdraw
      },
      checkMethodMinAmount: false,
      belowMinimumAmount: false,
      bankList: {},
      withdrawalBankList: [],
      hasNegativeBalance: false,
      freezeDialogVisible: false,
      withdrawalFreeze: false,
      day: 0,
      hour: 0,
      fromFunctionCode: ''
    };
  },
  watch: {
    'withdrawForm.withdrawalType'(value) {
      this.country = null;
      if (value) {
        this.withdrawalBankList = this.bankList.get(value);
      }
      this.chooseWithdrawalType = this.$refs.top.withdrawalTypes.find(f => f.id == value);
      this.transferFlag =
        this.chooseWithdrawalType && this.chooseWithdrawalType.withdrawSubTypes.length > 0 ? true : false;

      this.currentComponent = this.withdrawalTypesMap[value];
      if (Object.keys(this.bankList).length) this.withdrawalBankList = this.bankList.get(value);
      this.checkbelowMinAmount();
    },
    'withdrawForm.amount'(value) {
      this.checkbelowMinAmount();
    }
  },
  //get previous path
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  methods: {
    confirm() {
      if (this.dialog.method == 'noCard') this.goToClientPortalDrawDetail();
    },
    goToClientPortalDrawDetail() {
      signHelper.signOut('backToClientPortal', '/paymentDetails');
    },
    getCountryCode(code) {
      this.userCountryCode = code;
    },
    getTopForm(code, list) {
      this.userCountryCode = Number(code);
      this.bankList = list;
    },
    getBankList(countryBankList) {
      this.bankList = countryBankList;
    },
    withdrawFormChange(form, negativeBalance) {
      this.hasNegativeBalance = negativeBalance;
      this.withdrawForm = form;
    },
    formChange(form) {
      this.form = form;
    },
    getFormDataOfChild() {
      return this.$refs.child.getFormData && this.$refs.child.getFormData();
    },
    submitForm() {
      const top = this.$refs.top.submitForm(),
        comp = this.$refs.child.submitForm(),
        vm = this;
      Promise.all([top, comp])
        .then(function (values) {
          store.commit('withdraw/setInitialState');
          vm.applyWithdraw();
        })
        .catch(error => console.log(`Error in executing ${error}`));
    },
    // 出金请求
    applyWithdraw() {
      let customFormData = this.getFormDataOfChild();
      let form = customFormData || { ...this.form };

      let withdrawForm = { ...this.withdrawForm };

      let accountName = this.accountName;

      switch (this.withdrawForm.withdrawalType) {
        case 4:
          // 只有银联有省市
          // 把省市的ID转化为对应的名称
          form.bankProvince = ChineseDistricts['86'][this.form.bankProvince];
          form.bankCity = ChineseDistricts[this.form.bankProvince][this.form.bankCity];
          break;
        case 7:
          // 如果出金方式为 Fasapay,则accountName为bankName，不传用户名
          accountName = form.bankName;
          break;
        case 112:
          // 如果出金方式为 Alipay,则accountName为支付宝账号名，不传用户名
          accountName = form.accountName;
          break;
        default:
          break;
      }

      // Canada, 如果出金方式为 bank transfer international
      if (this.userCountryCode == 4575 && this.country == 2 && this.withdrawForm.withdrawalType === 2) {
        form.importantNotes = `Transit Number: ${this.$refs.child.$refs.bankChild.canadaOnly.transitNumber}, Institution Number: ${this.$refs.child.$refs.bankChild.canadaOnly.institutionNumber}, notes: ${this.form.importantNotes}`;
      }

      apiApplyWithdrawal(
        {
          ...withdrawForm,
          ...form,
          qUserId: this.userId,
          accountName: accountName
        },
        this.token
      ).then(resp => {
        if (resp.data.code === 638 || resp.data.code === 639) {
          this.day = resp.data.data.limitDayNumber;
          this.hour = resp.data.data.lastHours;
          //638为修改账号引发的限制   639为修改密码引发的限制
          this.fromFunctionCode = resp.data.code === 638 ? 'updateUserLogin' : 'updatePassword';
          this.freezeDialogVisible = true;
          return;
        }

        if (resp.data.code === 0) {
          this.$message.success(this.$t('withdraw.YourPaymentIsBeingProcessed'));
          this.bus.$emit('updateWithdrawHistory', true);
          this.$nextTick(function () {
            this.$router.push(this.prevRoute && this.prevRoute.path != '/' ? this.prevRoute.name : 'home');
          });
        } else {
          this.$message.error(resp.data.errmsg);
        }
      });
    },
    countryAUandIntel(value) {
      this.country = parseInt(value);
    },
    checkbelowMinAmount() {
      this.belowMinimumAmount =
        this.chooseWithdrawalType && this.withdrawForm.amount < this.chooseWithdrawalType.amountLimit;
    },
    getSecurityInfo() {
      apiGetSecurity().then(result => {
        const resp = result?.data;

        if (resp?.code === 0) {
          if (resp?.data.length > 0) {
            this.$router.push({ path: '/home' });
          }
        }
      });
    },
    checkFreezeWithdrawalStatus() {}
  },
  mounted() {
    this.getSecurityInfo();
    this.checkMethodMinAmount = this.$platform.getCheckMethodMinAmount(this.regulator);
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/css/pages/rebateWithdraw.scss';
.form_button {
  text-align: center;
}
.my_dialog /deep/ {
  .el-dialog {
    background: #1e1e1e;
  }
  h5 {
    color: white;
  }
  .el-dialog__header {
    i {
      color: white;
    }
  }
}
</style>
