import { apiSendEmailVerificationCode } from '@/resource/index'
import { mapState } from 'vuex'
import store from '@/store'
import { VERIFICATION_COUNT } from '@/constants/withdraw'
window.verificationTimer = null

export default {
  computed: {
    ...mapState('common', ['email']),
    ...mapState('withdraw', ['count', 'codeBtnDisabled']),
    // verification code down for page
    formatCount() {
      return this.count === 0 ? 0 : `(${this.count}s)`
    },
  },
  data() {
    return {
      txId: '',
    }
  },
  methods: {
    getCryptoEmail() {
      const email = this.email
      let tmp = email.split('@')
      let name = tmp[0].split('')
      let newName = name[0] + '***' + name[name.length - 1] + '@' + tmp[1]
      return newName
    },
    countDown() {
      let verCount = this.count
      if (window.verificationTimer) {
        store.commit('withdraw/clearTimer')
      }
      window.verificationTimer = setInterval(() => {
        if (verCount <= 0) {
          store.commit('withdraw/clearTimer')
          store.commit('withdraw/setCodeBtnDisabled', false)
          store.commit('withdraw/setCount', VERIFICATION_COUNT)
          verCount = VERIFICATION_COUNT
        } else {
          verCount -= 1
          store.commit('withdraw/setCount', verCount)
        }
      }, 1000)
    },
    async sendCode() {
      try {
        let res = await apiSendEmailVerificationCode({ namespace: 'IB_WITHDRAW' })
        console.log(res, 'send code res')
        if (res.data.code === 0) {
          // this.txId = res.data.data.txId;
          sessionStorage.setItem('txId', res.data.data.txId);
          store.commit('withdraw/setCodeBtnDisabled', true)
          this.countDown()
        } else {
          this.$message({ message: this.$t('withdraw.verificationCodeFailed'), type: 'error' })
        }
      } catch (error) {
        this.$message({ message: this.$t('withdraw.verificationCodeFailed'), type: 'error' })
      }
    },
  },
  mounted() {
    if(this.count !== VERIFICATION_COUNT || this.codeBtnDisabled === true) {
      this.countDown();
    }
  }
}
