<template>
  <div>
    <vDialog id="UploadIdOrAddress" :visible.sync="show">
      <div slot="header" class="title">
        {{ $t('identityProof.home.header1') }}
        <i class="el-icon-warning-outline text-white"></i>
      </div>
      <div v-html="$t('identityProof.popup.header', { name: individualUserName })" class="top"></div>
      <div class="body">
        <Block
          :limit="6"
          :PendingReason="poaPendingReason"
          :customPendingReason="poaCustomPendingReason"
          method="address"
          v-if="requireAddressProof || keepUploadPOA"
          @syncFile="syncPoaFile"
        ></Block>
      </div>
      <div class="errMsg" v-if="errorMsgTrigger">{{ $t('common.formValidation.file') }}</div>
      <div class="bottom" @click="onSubmit">{{ $t('common.button.upload') }}</div>
    </vDialog>
    <vDialog id="success" :visible.sync="success">
      <i class="icon el-icon-circle-check text-green mb-5"></i>
      <p>{{ $t('identityProof.success') }}</p>
    </vDialog>
  </div>
</template>

<script>
import vDialog from '@/components/home/vDialog';
import Block from '@/components/home/UploadIdAddress/Block';
import { apiVerificationIdentityProofs, apiAttach_new_document } from '@/resource';

export default {
  props: {
    visible: Boolean,
    poaPendingReason: String,
    poaCustomPendingReason: String,
    poaRecode: Number
  },
  data() {
    return {
      individualUserName: this.$store.state.common.userName,
      show: false,
      success: false,
      errorMsgTrigger: false,
      poaFiles: []
    };
  },
  components: { vDialog, Block },
  computed: {
    requireAddressProof() {
      return this.$store.state.common.requireAddressProof;
    },
    keepUploadPOA() {
      return this.$store.state.common.keepUploadPOA;
    }
  },
  watch: {
    show(bool) {
      this.disableVisible(bool);
    },
    visible: {
      immediate: true,
      handler(bool) {
        this.show = bool;
      }
    }
  },
  methods: {
    syncPoaFile(files) {
      this.poaFiles = files;
    },
    disableVisible(bool) {
      this.$emit('update:visible', bool);
    },
    onSubmit() {
      if (this.poaFiles.length == 0) {
        this.errorMsgTrigger = true;
        return;
      }

      if (this.requireAddressProof && this.poaFiles.length > 0) this.standardInsert();

      if (this.poaRecode && this.poaFiles.length > 0) this.attachInsertAPi(this.poaRecode, this.poaFiles);
    },
    getStandardData() {
      return {
        filePathList: [...this.poaFiles],
        fileType: 11
      };
    },
    standardInsert() {
      apiVerificationIdentityProofs(this.getStandardData()).then(resp => {
        if (resp.data.code == 0) {
          this.success = true;

          if (this.poaFiles.length > 0 && this.poaRecode == null) {
            this.$store.commit('common/setPendingAddressProofApproval', true);
            this.$store.commit('common/setRequireAddressProof', false);
            this.poaFiles = [];
          }
        }
        this.disableVisible(false);
      });
    },
    attachInsertAPi(id, files) {
      apiAttach_new_document({ documentId: id, documentType: 11, docPaths: files }).then(resp => {
        if (resp.data.code == 0) {
          this.$store.commit('common/setKeepUploadPOA', false);
          this.$store.commit('common/setPendingAddressProofApproval', true);
          this.poaFiles = [];
        }
        this.disableVisible(false);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/uploadIdOrAddress/index.scss';
</style>
