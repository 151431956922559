<template>
  <el-form :model="form" label-position="top" :rules="TransferFormRules" ref="withdrawFormChild">
    <div class="form_list_inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.default.selectBA')" prop="selectedCardID">
            <el-select v-model="form.selectedCardID" data-testid="selectedCardID">
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, 2, dropdownTranslationObj)"
                :data-testid="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div v-if="form.selectedCardID || form.selectedCardID === 0">
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.BankName')" prop="bankName">
              <el-select v-model="form.bankName" data-testid="bankName">
                <el-option
                  v-for="item in bankList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                  :data-testid="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.BankAddress')" prop="bankAddress">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.bankAddress"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="bankAddress"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.AccountNumberIBAN')" prop="accountNumber">
              <el-input
                maxlength="100"
                type="text"
                v-model.trim="form.accountNumber"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="accountNumber"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.BankBeneficiaryName')" prop="beneficiaryName">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.beneficiaryName"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="beneficiaryName"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.ifscCode')" prop="ifscCode">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.ifscCode"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="ifscCode"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.BankBranch')" prop="bankBranchName">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.bankBranchName"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="bankBranchName"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.email')" prop="email">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.email"
                auto-complete="off"
                data-testid="email"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.phone')" prop="phoneNumber">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.phoneNumber"
                auto-complete="off"
                data-testid="phoneNumber"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.ImportantNotes')">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.importantNotes"
                auto-complete="off"
                data-testid="importantNotes"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
        <el-checkbox v-model="isRemember" data-testid="remember"> {{ $t('withdraw.default.remember') }}</el-checkbox>
      </div>
    </div>
  </el-form>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import { validateEmail } from '@/util/validators';

export default {
  props: {
    withdrawalType: Number,
    bankList: {
      type: Array,
      default: () => []
    }
  },
  mixins: [withdrawlMixin, bankTransferMixin],
  data() {
    return {
      form: {
        bankName: '',
        bankAddress: '',
        accountNumber: '',
        beneficiaryName: '',
        email: '',
        phoneNumber: '',
        importantNotes: '',
        userPaymentInfoId: '',
        ifscCode: '',
        bankBranchName: '',
        selectedCardID: null
      },
      isRemember: true,
      TransferFormRules: {
        bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankName'),
            trigger: 'blur'
          }
        ],
        bankAddress: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankAddress'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccountNumber,
            trigger: 'blur'
          }
        ],
        beneficiaryName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.beneName'),
            trigger: 'blur'
          }
        ],
        ifscCode: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.ifscCode')
            }),
            trigger: 'blur'
          }
        ],
        bankBranchName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.branchName'),
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.email')
            }),
            trigger: 'blur'
          },
          {
            validator: validateEmail(this.$t('withdraw.formErrorMsg.emailFormat')),
            trigger: 'blur'
          }
        ],
        phoneNumber: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.phone')
            }),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    },
    'form.selectedCardID'(value) {
      const selectedCardInfo = this.availableCards.find(item => item.id === value);
      console.log(selectedCardInfo);

      let existingAccount = selectedCardInfo && selectedCardInfo.id !== -1 ? true : false;
      this.form = {
        ...this.form,
        bankName: selectedCardInfo.bankName || '',
        bankAddress: selectedCardInfo.bankAddress || '',
        accountNumber: selectedCardInfo.accountNumber || '',
        beneficiaryName: selectedCardInfo.beneficiaryName || '',
        importantNotes: selectedCardInfo.importantNotes || '',
        ifscCode: selectedCardInfo.ifscCode || '',
        email: selectedCardInfo.email || '',
        phoneNumber: selectedCardInfo.phoneNumber || '',
        bankBranchName: selectedCardInfo.bankBranchName || '',
        isRememberInfo: existingAccount ? (selectedCardInfo.id ? '' : this.isRemember) : this.isRemember,
        userPaymentInfoId: existingAccount ? selectedCardInfo.id : null
      };

      this.isdisabled = existingAccount ? true : false;
    },
    'form.accountNumber'(value) {
      this.form.accountNumber = this.latinNumberValidator(value);
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormChild.validate();
    }
  }
};
</script>
