<template>
  <Card>
    <template v-slot:header>
      <span
        >{{ $t('notification.pendingIdentityProof.header') }}
        <em class="el-icon-warning-outline text-primary-alt"></em>
      </span>
    </template>
    <div class="mb-3">
      <i18n path="notification.pendingIdentityProof.content" tag="p">
        <template v-slot:doc>{{ $t('notification.pendingIdentityProof.poa') }}</template>
      </i18n>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/Card';

export default {
  name: 'Notification',
  components: { Card },
  props: { id: Boolean, poa: Boolean }
};
</script>
