function base64ToArrayBuffer(data) {
  const bString = window.atob(data)
  const bLength = bString.length
  let bytes = new Uint8Array(bLength)
  for (let i = 0; i < bLength; i++) {
    bytes[i] = bString.charCodeAt(i)
  }
  return bytes
}

export default function pdfHandler(data, filename) {
  const bufferArray = base64ToArrayBuffer(data)
  const blob = new Blob([bufferArray], { type: 'application/pdf' })
  const objectUrl = URL.createObjectURL(blob)
  const link = document.createElement('a')
  const linkId = 'export_file'
  link.setAttribute('id', linkId)
  link.setAttribute('href', objectUrl)
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.getElementById(linkId).remove()
}
