<template>
  <el-form :model="form" label-position="top" :rules="rulesSticpay" ref="withdrawFormSticpay">
    <div class="form_list_inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.SticpayEmail')" prop="skrillEmail">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.skrillEmail"
              auto-complete="off"
              data-testid="skrillEmail"
            ></el-input>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.ImportantNotes')">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.importantNotes"
              auto-complete="off"
              data-testid="importantNotes"
            ></el-input>
          </el-form-item>
        </li>
      </ul>
    </div>
  </el-form>
</template>
<script>
import { validateEmail } from '@/util/validators';

export default {
  data() {
    return {
      form: {
        skrillEmail: '',
        importantNotes: ''
      },
      rulesSticpay: {
        skrillEmail: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.email'),
            trigger: 'blur'
          },
          {
            validator: validateEmail(this.$t('withdraw.formErrorMsg.emailFormat')),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler: function(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormSticpay.validate();
    }
  }
};
</script>
