<template>
  <el-form :model="form" label-position="top" :rules="TransferFormRules" ref="withdrawForm">
    <div class="form_list_inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.default.selectBA')" prop="selectedCardID">
            <el-select v-model="form.selectedCardID" data-testid="selectedCardID">
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, 2, dropdownTranslationObj)"
                :data-testid="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div v-if="form.selectedCardID || form.selectedCardID === 0">
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.BankName')" prop="bankName">
              <el-select v-model="form.bankName" :disabled="isdisabled" data-testid="bankName">
                <el-option
                  v-for="item in bankList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                  :data-testid="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.BankBeneficiaryName')" prop="beneficiaryName">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.beneficiaryName"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="beneficiaryName"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.BankAccountNumber')" prop="accountNumber">
              <el-input
                maxlength="100"
                type="text"
                v-model.trim="form.accountNumber"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="accountNumber"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.AccountType')" prop="pixKeyType">
              <el-select v-model="form.pixKeyType" :disabled="isdisabled" data-testid="pixKeyType">
                <el-option
                  v-for="item in accountType"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                  :data-testid="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form_list_inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.DocumentType')" prop="documentType" required>
              <el-select v-model="form.documentType" :disabled="isdisabled" data-testid="documentType">
                <el-option
                  v-for="item in documentType"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                  :data-testid="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.DocumentDetails')" prop="documentDetails">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.documentDetails"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="documentDetails"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.ImportantNotes')">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.importantNotes"
                auto-complete="off"
                data-testid="importantNotes"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>

      <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
        <el-checkbox v-model="isRememberInfo" data-testid="remember">
          {{ $t('withdraw.default.remember') }}</el-checkbox
        >
      </div>
    </div>
  </el-form>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import vUpload from '@/components/vUpload';

export default {
  props: ['withdrawalType', 'bankList', 'userCountryCode'],
  mixins: [bankTransferMixin, withdrawlMixin],
  components: { vUpload },
  data() {
    return {
      documentType: [],
      accountType: [],
      form: {
        bankName: '',
        beneficiaryName: '',
        accountNumber: '',
        documentType: '',
        documentDetails: '',
        pixKeyType: '',
        importantNotes: '',
        userPaymentInfoId: '',
        selectedCardID: null
      },
      isRememberInfo: true,
      TransferFormRules: {
        bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankName'),
            trigger: ['blur', 'change']
          }
        ],
        beneficiaryName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.beneName'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccountNumber,
            trigger: 'blur'
          }
        ],
        documentType: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.DocumentType')
            }),
            trigger: ['blur', 'change']
          }
        ],
        documentDetails: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.DocumentDetails')
            }),
            trigger: ['blur', 'change']
          }
        ],
        pixKeyType: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.bankAccType')
            }),
            trigger: ['blur', 'change']
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    },
    withdrawalType: {
      handler(val) {
        switch (val) {
          case 88:
            return (
              (this.accountType = [
                { value: 'Savings', label: 'Savings' },
                { value: 'Checking', label: 'Checking' },
                { value: 'VISTA', label: 'VISTA' },
                { value: 'RUT', label: 'RUT' },
                { value: 'SALARY', label: 'SALARY' },
                { value: 'Electronic Check', label: 'Electronic Check' }
              ]),
              (this.documentType = [
                { value: 'RUT', label: 'RUT' },
                { value: 'RUN', label: 'RUN' },
                { value: 'PASS', label: 'PASS' },
                { value: 'CE', label: 'CE' }
              ])
            );
            break;
          case 63:
            return (
              (this.accountType = [
                { value: 'Savings', label: 'Savings' },
                { value: 'Checking', label: 'Checking' },
                { value: 'Debit', label: 'Debit' },
                { value: 'Phone', label: 'Phone' },
                { value: 'Clabe', label: 'Clabe' }
              ]),
              (this.documentType = [
                { value: 'RFC', label: 'RFC' },
                { value: 'CURP', label: 'CURP' }
              ])
            );
            break;

          case 89:
            return (
              (this.accountType = [
                { value: 'Savings', label: 'Savings' },
                { value: 'Checking', label: 'Checking' }
              ]),
              (this.documentType = [
                { value: 'CC', label: 'CC' },
                { value: 'CE', label: 'CE' },
                { value: 'NT', label: 'NT' },
                { value: 'TI', label: 'TI' },
                { value: 'NE', label: 'NE' }
              ])
            );
            break;
          case 72:
            return (
              (this.accountType = [
                { value: 'Savings', label: 'Savings' },
                { value: 'Checking', label: 'Checking' }
              ]),
              (this.documentType = [
                { value: ' DNI', label: ' DNI' },
                { value: 'RUC', label: 'RUC' },
                { value: 'CE', label: 'CE' }
              ])
            );
            break;
          case 90:
            return (
              (this.accountType = [
                { value: 'Savings', label: 'Savings' },
                { value: 'Checking', label: 'Checking' },
                { value: 'Master', label: 'Master' }
              ]),
              (this.documentType = [
                { value: ' DNI', label: ' DNI' },
                { value: 'RUC', label: 'RUC' },
                { value: 'PAS', label: 'PAS' },
                { value: 'CE', label: 'CE' }
              ])
            );
            break;
        }
      },
      deep: true,
      immediate: true
    },
    'form.selectedCardID'(value) {
      const selectedCardInfo = this.availableCards.find(item => item.id === value);

      this.form = {
        ...this.form,
        bankName: selectedCardInfo.bankName || '',
        accountNumber: selectedCardInfo.accountNumber || '',
        beneficiaryName: selectedCardInfo.beneficiaryName || this.$store.state.common.userName,
        documentType: selectedCardInfo.documentType || '',
        documentDetails: selectedCardInfo.documentDetails || '',
        pixKeyType: selectedCardInfo.pixKeyType || '',
        importantNotes: selectedCardInfo.importantNotes || '',
        isRememberInfo:
          selectedCardInfo && selectedCardInfo.id !== -1
            ? selectedCardInfo.id
              ? ''
              : this.isRemember
            : this.isRemember,
        userPaymentInfoId: selectedCardInfo && selectedCardInfo.id !== -1 ? selectedCardInfo.id : null
      };

      this.isdisabled = selectedCardInfo && selectedCardInfo.id !== -1 ? true : false;
    },
    'form.accountNumber'(value) {
      this.form.accountNumber = this.latinNumberValidator(value);
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawForm.validate();
    },

    getFormData() {
      return {
        withdrawalType: this.withdrawalType,
        bankName: this.form.bankName,
        beneficiaryName: this.form.beneficiaryName,
        accountNumber: this.form.accountNumber,
        documentType: this.form.documentType,
        documentDetails: this.form.documentDetails,
        pixKeyType: this.form.pixKeyType,
        importantNotes: this.form.importantNotes,
        isRememberInfo: this.form.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.form.userPaymentInfoId ? this.form.userPaymentInfoId : '',
        targetCurrency: this.form.withdrawCurrency
      };
    }
  }
};
</script>
