import { apiGet_last_identifiy_proof } from '@/resource'

export default {
  data() {
    return {
      poaPendingReason: null,
      poaCustomPendingReason: '',
      poaRecode: null,
    }
  },
  methods: {
    getPoa() {
      if (this.pendingAddressProofApproval) {
        this.poaPendingReason = null
        this.poaCustomPendingReason = null
        apiGet_last_identifiy_proof(11).then(resp => {
          const data = resp.data
          if (data.code == 0) {
            if (data.data.status == 1) {
              this.poaRecode = data.data.id
              this.$store.commit('common/setKeepUploadPOA', true)

              if (data.data.pending_reason == 7) this.poaCustomPendingReason = data.data.custom_pending_reason
              else this.poaPendingReason = this.$t(`identityProof.reasonType.${data.data.pending_reason}`)
            }
            if (data.data.status == 2) {
              this.$store.commit('common/setRequireAddressProof', false)
              this.$store.commit('common/setPendingAddressProofApproval', false)
            }
            if (data.data.status == 3) {
              this.$store.commit('common/setRequireAddressProof', true)
              this.$store.commit('common/setPendingAddressProofApproval', false)
            }
            if (data.data.status == 4) {
              this.$store.commit('common/setRequireAddressProof', false)
              this.$store.commit('common/setPendingAddressProofApproval', true)
            }
          }
        })
      }
    },
  },
  computed: {
    pendingAddressProofApproval() {
      return this.$store.state.common.pendingAddressProofApproval
    },
    requireAddressProof() {
      return this.$store.state.common.requireAddressProof
    },
    keepUploadPOA() {
      return this.$store.state.common.keepUploadPOA
    },
    showIdentityProofAlert() {
      return this.requireAddressProof || this.keepUploadPOA
    },
    idPoaPending() {
      return this.pendingAddressProofApproval && !this.showIdentityProofAlert
    },
  },
}
